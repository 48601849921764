import React, {useEffect} from "react";
import { useGlobalContext } from "../context";
import ConactHeroImage from "../Components/ContactImage";
import Navbar from "../Components/Navbar";
import ContactForm from "../Components/ContactForm";

const ContactUs = () => {

  const {updateContactPage} = useGlobalContext();

  useEffect( () => updateContactPage(), []);

  return(
    <>

     {/* <Navbar/> */}
    <ConactHeroImage/>
    <ContactForm/>
  
   </>
  )
}

export default ContactUs;