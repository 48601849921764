import React from "react";
import styled from "styled-components";
import { useGlobalContext } from "../context";


const ConactHeroImage = ()  => {
  
    const {ContactHeroImg} = useGlobalContext();

    return(
        <Wrapper>
            <div className="contactHero-img">
              <img alt="contact-hero" src={ContactHeroImg} />

            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
.contactHero-img {
    img {
        width: 100%;
        height: 70vh;
    }
    
}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .contactHero-img {
    img {
        
        height: 30vh;
    }
    
}
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
    .contactHero-img {
    img {
        height: 30vh;
    }
    
}
}

`;

export default ConactHeroImage;