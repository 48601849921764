import React, {useEffect} from "react";
import { useGlobalContext } from "../context";
import HeroImageSection from "../Components/HeroImageSection";
import ReachOut from "../Components/ReachOut";
import AboutUsSection from "../Components/AboutUs.Section";
import Navbar from "../Components/Navbar";

const AboutUs = () => {

  const {updateAboutPage} = useGlobalContext();

  useEffect( () => updateAboutPage(), []);

  return(
    <>

     {/* <Navbar/> */}
     <HeroImageSection/>
     <AboutUsSection/>
     <ReachOut/>
     
    </>
  )
}

export default AboutUs;