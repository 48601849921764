import React from "react";


import { useEffect } from "react";
import HeroSection from "../Components/HeroSection";
import { useGlobalContext } from "../context";
import HeroImageSection from "../Components/HeroImageSection";
import ReachOut from "../Components/ReachOut";
import Benefits from "../Components/Benefits";
import OurServices from "../Components/OurServices";
import OurTechStack from "../Components/OurTechStack";
import Navbar from "../Components/Navbar";
import AccordionFAQ from "../Components/AccordionFAQ";
import Header from "../Components/Header";

const Home = () => {
  
  const {updateHomePage} = useGlobalContext();

  useEffect(() => updateHomePage(), []);

  const faqItems = [
    {
      question: 'What is web app design and why it is important ?',
      answer: 'Web app design is the process of creating the visual layout and structure of a web app, and its important because it influences user experience brand perception and online success',
    },
    {
      question: 'What goes into web app design ?',
      answer: 'Web app design involves the process of creating and organizing visual elements, content, and user interactions to deliver an aesthetically pleasing and user-friendly experience that aligns with the web apps purpose and goals.',
    },
    {
      question: 'How much does a web app cost ?',
      answer: 'The cost of a web app can vary significantly depending on its complexity, features, development time, and the expertise of the developers, ranging from a few thousand dollars to several hundred thousand dollars.',
    },
    {
      question: 'Do i need to have web app for my business?',
      answer: 'Having a web app for your business can significantly enhance user experience, accessibility, and engagement, but its necessity depends on your specific business needs, target audience, and the complexity of your services or products.',
    },
    {
      question: 'How do i hire webapp agency ?',
      answer: 'Contact number 8975663020',
    },
    {
      question: 'How long does it takes to design a webapp ?',
      answer: 'The time it takes to design a web app can vary based on its complexity, features, and the efficiency of the development team, typically ranging from a few weeks to several months.',
    },
    // Add more FAQ items as needed
  ];

  return(
    <>
    
  {/* <Header/> */}
    
    {/* <Navbar/> */}
    <HeroImageSection/>
    <HeroSection />
    <OurServices/>
    <Benefits/>
    <OurTechStack/>
    <AccordionFAQ  items={faqItems} />
    <ReachOut/>
    
    </>
  


  )
}

export default Home;