
//consumer / useContex

import React, {useContext, useReducer}  from "react";
import reducer from "./reducer"

//create a context(warehouse)
const AppContext = React.createContext();

const initialstate = {

    //Navbar
    LogoWcs:"",


    //Hero-Image-Section
    heroImg:"",
    heroHeading:"",
    heroPara:"",
    ContactHeroImg:"",
 
    //Our-Services-Home
    ourServicesHead:"",
    ourServicesSub:"",

    ourServicesDesHead01:"",
    ourServicesDesText01:"",
    ourServicesFeature01A:"",
    ourServicesFeature01B:"",
    ourServicesFeature01C:"",
    ourServicesFeature01D:"",

    ourServicesImage01:"",

    ourServicesDesHead02:"",
    ourServicesDesText02:"",
    ourServicesFeature02A:"",
    ourServicesFeature02B:"",
    ourServicesFeature02C:"",
    ourServicesFeature02D:"",

    ourServicesImage02A:"",
    ourServicesImage02B:"",


    


    //Result-Driven_benefits
    benefitsHead: "",
    benefitsSubHead: "",

    benefitsDesHead01: "",
    benefitsDesText01: "",
    benefitsImage01:"",

    benefitsDesHead02:"",
    benefitsDesText02:"",
    benefitsImage02:"",

    benefitsDesHead03:"",
    benefitsDesText03:"",
    benefitsImage03:"",

    benefitsDesHead04:"",
    benefitsDesText04:"",
    benefitsImage04:"",
    
    benefitsDesHead05:"",
    benefitsDesText05:"",
    benefitsImage05:"",

    //Our-Tech-Stack
    techIcon01:"",
    techIcon02:"",
    techIcon03:"",
    techIcon04:"",

    techStackHead:"",
    techStackSub:"",

    techImg01:"",
    

    //Accordion FAQ
    FaqHead:"",
    FaqSubHead:"",
    AccordionIcon:"",





    //Features-List
    FeatureHead:"",

    FeatureIcon01:"",
    FeatureSubHead01:"",
    FeatureText01:"",

    FeatureIcon02:"",
    FeatureSubHead02:"",
    FeatureText02:"",

    FeatureIcon03:"",
    FeatureSubHead03:"",
    FeatureText03:"",

    FeatureIcon04:"",
    FeatureSubHead04:"",
    FeatureText04:"",

    FeatureIcon05:"",
    FeatureSubHead05:"",
    FeatureText05:"",

    FeatureIcon06:"",
    FeatureSubHead06:"",
    FeatureText06:"",

    FeatureIcon07:"",
    FeatureSubHead07:"",
    FeatureText07:"",

    FeatureIcon08:"",
    FeatureSubHead08:"",
    FeatureText08:"",

    FeatureIcon09:"",
    FeatureSubHead09:"",
    FeatureText09:"",

    //Design-Process
    DesignProcessHead:"",
    DesignProcessSub:"",
    DesignProcessIcon:"",
    
   

    StepNumber01:"",
    StepHead01:"",
    StepDes01:"",
    StepIcon01:"",

    StepNumber02:"",
    StepHead02:"",
    StepDes02:"",
    StepIcon02:"",

    StepNumber03:"",
    StepHead03:"",
    StepDes03:"",
    StepIcon03:"",

    StepNumber04:"",
    StepHead04:"",
    StepDes04:"",
    StepIcon04:"",

    StepNumber05:"",
    StepHead05:"",
    StepDes05:"",
    StepIcon05:"",

    StepNumber06:"",
    StepHead06:"",
    StepDes06:"",
    StepIcon06:"",

    StepNumber07:"",
    StepHead07:"",
    StepDes07:"",
    StepIcon07:"",

    StepNumber08:"",
    StepHead08:"",
    StepDes08:"",
    StepIcon08:"",
   
    StepNumber09:"",
    StepHead09:"",
    StepDes09:"",
    StepIcon09:"",

    //OUR-WORK
    BusinessCategoryScreen01:"",
    BusinessCategory01:"",

    BusinessCategoryScreen02:"",
    BusinessCategory02:"",

    BusinessCategoryScreen03:"",
    BusinessCategory03:"",

    BusinessCategoryScreen04:"",
    BusinessCategory04:"",

    BusinessCategoryScreen05:"",
    BusinessCategory05:"",

    BusinessCategoryScreen06:"",
    BusinessCategory06:"",

    BusinessCategoryScreen07:"",
    BusinessCategory07:"",

    BusinessCategoryScreen08:"",
    BusinessCategory08:"",

    BusinessCategoryScreen09:"",
    BusinessCategory09:"",

    BusinessCategoryScreen10:"",
    BusinessCategory10:"",

    BusinessCategoryScreen11:"",
    BusinessCategory11:"",

    BusinessCategoryScreen12:"",
    BusinessCategory12:"",

    BusinessCategoryScreen13:"",
    BusinessCategory13:"",

    BusinessCategoryScreen14:"",
    BusinessCategory14:"",

    BusinessCategoryScreen15:"",
    BusinessCategory15:"",

    BusinessCategoryScreen16:"",
    BusinessCategory16:"",

    BusinessCategoryScreen17:"",
    BusinessCategory17:"",

    BusinessCategoryScreen18:"",
    BusinessCategory18:"",

    BusinessCategoryScreen19:"",
    BusinessCategory19:"",

    BusinessCategoryScreen20:"",
    BusinessCategory20:"",



    EcomCategory01:"",
    ecomCategoryScreen01a:"",
    ecomCategory01a:"",
    ecomCategoryScreen01b:"",
    ecomCategory01b:"",
    ecomCategoryScreen01c:"",
    ecomCategory01c:"",

    EcomCategory02:"",
    ecomCategoryScreen02a:"",
    ecomCategory02a:"",
    ecomCategoryScreen02b:"",
    ecomCategory02b:"",
    ecomCategoryScreen02c:"",
    ecomCategory02c:"",
    ecomCategoryScreen02d:"",
    ecomCategory02d:"",
    ecomCategoryScreen02e:"",
    ecomCategory02e:"",
    ecomCategoryScreen02f:"",
    ecomCategory02f:"",

    EcomCategory03:"",
    ecomCategoryScreen03a:"",
    ecomCategory03a:"",
    ecomCategoryScreen03b:"",
    ecomCategory03b:"",
    ecomCategoryScreen03c:"",
    ecomCategory03c:"",
    ecomCategoryScreen03d:"",
    ecomCategory03d:"",
    ecomCategoryScreen03e:"",
    ecomCategory03e:"",
    ecomCategoryScreen03f:"",
    ecomCategory02f:"",
    ecomCategoryScreen03g:"",
    ecomCategory03g:"",

    EcomCategory04:"",
    ecomCategoryScreen04a:"",
    ecomCategory04a:"",
    ecomCategoryScreen04b:"",
    ecomCategory04b:"",
    ecomCategoryScreen04c:"",
    ecomCategory04c:"",

    EcomCategory05:"",
    ecomCategoryScreen05a:"",
    ecomCategory05a:"",
    ecomCategoryScreen05b:"",
    ecomCategory05b:"",
    ecomCategoryScreen05c:"",
    ecomCategory05c:"",
    ecomCategoryScreen05d:"",
    ecomCategory05d:"",

    EcomCategory06:"",
    ecomCategoryScreen06a:"",
    ecomCategory06a:"",
    ecomCategoryScreen06b:"",
    ecomCategory06b:"",

    EcomCategory07:"",
    ecomCategoryScreen07a:"",
    ecomCategory07a:"",
    ecomCategoryScreen07b:"",
    ecomCategory07b:"",
    ecomCategoryScreen07c:"",
    ecomCategory07c:"",
    ecomCategoryScreen07d:"",
    ecomCategory07d:"",

    EcomCategory08:"",
    ecomCategoryScreen08a:"",
    ecomCategory08a:"",
    ecomCategoryScreen08b:"",
    ecomCategory08b:"",

    //ABOUT-US-SECTION
    AboutHead:"",
    AboutPara:"",
    AboutHeroImg:"",

    AboutCardHead01:"",
    AboutCardPara01:"",
    AboutCardHead02:"",
    AboutCardPara02:"",

    AboutFeatureHead:"",
    FeatureHead01:"",
    AboutFeaturePara01:"",
    FeatureHead02:"",
    AboutFeaturePara02:"",
    FeatureHead03:"",
    AboutFeaturePara03:"",
    FeatureHead04:"",
    AboutFeaturePara04:"",
    FeatureHead05:"",
    AboutFeaturePara05:"",
    FeatureHead06:"",
    AboutFeaturePara06:"",

    ProfileHead01:"",
    ProfileImg01:"",
    ProfileName01:"",
    ProfileHead02:"",
    ProfileImg02:"",
    ProfileName02:"",

    //Contact Form
    ContactFormHead:"",
    ContactFormIcon:"",
   
    
    name:"",
    image:"",
    

};


//provider
const AppProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialstate);


//...HOME_PAGE...
    const updateHomePage = () => {
        return dispatch(
            {
                type:"HOME_UPDATE",
                payload: {

                    //Navbar
                    LogoWcs:"./images/LogoWcs.png",

                     //Hero-Image-Section
                     heroImg:"./images/HomeHeroImage.png",
                     heroHeading: "Web Consultancy Services",
                     heroPara:"Brings your business online",

                     subHeroHeading:"Your path to Digital Excellence Starts Here.",
                     subHeroPara:"We provide guidance, expertise and solutions for consultancy & e-commerce business.",
                    image1:"./images/subHeroImg1.png",
                    image2:"./images/subHeroImage2.png",
                    image3:"./images/subHeroImage3.png",


                    //Our-Services-Home
                    ourServicesHead:"OUR SERVICES",
                    ourServicesSub:"We Are Specialized In The Following Services",
                    
                    ourServicesDesHead01:"Consultancy Business Web App",
                    ourServicesDesText01:"If your business does not yet have a website, then it is time to have an online presence that communicates the brand of your company, as well as that displays information about services or products.",

                    ourServicesFeature01A:"Interactive Features",
                    ourServicesFeature01B:"User Friendly",
                    ourServicesFeature01C:"UX & UI Implementation",
                    ourServicesFeature01D:"Speed Performance",
                    
                    ourServicesImage01:"./images/ourServicesImg01.png",

                    ourServicesDesHead02:"e-Commerce Web App",
                    ourServicesDesText02:"An e-commerce web app provides a platform for customers to browse through products, select item they want to purchase, add them to virtual shopping cart then proceed to checkout to complete the purchase. It have become increasingly popular as more businesses seek to establish an online presence & reach a global customer base.",

                    ourServicesFeature02A:"Highly Secure Checkout",
                    ourServicesFeature02B:"User Friendly",
                    ourServicesFeature02C:"Payment Integration",
                    ourServicesFeature02D:"Mobile Optimized",

                    ourServicesImage02A:"./images/ourServicesImg02.png",
                    ourServicesImage02B:"./images/ourServicesImg03.png",







                   //Result-Driven_benefits
                   benefitsHead: "RESULT DRIVEN",
                   benefitsSubHead: "Web Apps Offer A Range Of Benefits For Businesses Across Various Industries",

                   benefitsDesHead01:"24/7 Availability",
                   benefitsDesText01: "Unlike traditional stores with fixed operating hours, e-commerce platforms are accessible 24/7. Customers can browse products, make purchases, and seek support at their convenience, accommodating different time zones and preferences.",
                   benefitsImage01: "./images/benefitsImg01.png",

                   benefitsDesHead02:"Wider Product Range",
                   benefitsDesText02:"With an e-commerce web app, businesses can offer a broader range of products without the physical space limitations of a traditional store.",
                   benefitsImage02:"./images/benefitsImg02.png",

                   benefitsDesHead03:"Easy Scalability",
                   benefitsDesText03:"E-commerce platforms provide scalability options, allowing your business to grow and adapt to changing demands.",
                   benefitsImage03:"./images/benefitsImg03.png",

                   benefitsDesHead04:"Cost Savings",
                   benefitsDesText04:"E-commerce can be more cost-effective than traditional brick-and-mortar retail. It eliminates the need for physical storefronts, reduces overhead costs associated with maintaining a physical space, and minimizes expenses related to inventory management.",
                   benefitsImage04:"./images/benefitsImg04.png",
                   
                   benefitsDesHead05:"Global Reach",
                   benefitsDesText05:"E-commerce eliminates geographical barriers, allowing businesses to reach a global customer base. Customers can access your online store from anywhere, at any time,",
                   benefitsImage05:"./images/benefitsImg05.png",

                    //Our-Tech-Stack
                    techIcon01:"./images/techIcon01.png",
                    techIcon02:"./images/techIcon02.png",
                    techIcon03:"./images/techIcon03.png",
                    techIcon04:"./images/techIcon04.png",

                    techStackHead:"OUR TECH STACK",
                    techStackSub:"Modern Technologies To Drive You Forward",

                    techImg01:"./images/techImg.png",
                   

                    

                    //Accordion FAQ
                    FaqHead:"FREQUENT QUESTIONS",
                    FaqSubHead:"F.A.Q.",
                    AccordionIcon:"./images/AccordionIcon.png",



                   


                }
            }
        )
    }
    
//...SERVICES_PAGE...
  const updateServicesPage = () => {
    return dispatch(
        {
            type:"SERVICES_UPDATE",
            payload: {

                //Navbar
                LogoWcs:"./images/LogoWcs.png",

                  //Hero-Image-Section
                  heroImg:"./images/ServicesHeroImage.png",
                  heroHeading: "Our Services",
                  heroPara:"What We've Got For You",

                  //Result-Driven_benefits
                  benefitsHead: "OUR SERVICES",
                  benefitsSubHead: "Web App Development",

                  benefitsDesHead01:" For Strong Online Presence",
                  benefitsDesText01: "We offers a range of services to assist clients in maximizing their online presence and achieving their digital goals.",
                  benefitsImage01: "./images/serviceImg01.png",

                  benefitsDesHead02:"Easy to Customize",
                  benefitsDesText02:"We create secureand easy-to-customize websites that let you easily update the content yourself using auser-friendly administration panel.",
                  benefitsImage02:"./images/serviceImg02.png",

                  benefitsDesHead03:"Easy Scalability",
                  benefitsDesText03:"E-commerce platforms provide scalability options, allowing your business to grow and adapt to changing demands.",
                  benefitsImage03:"./images/serviceImg03.png",

                  benefitsDesHead04:"Cost Savings",
                  benefitsDesText04:"E-commerce can be more cost-effective than traditional brick-and-mortar retail. It eliminates the need for physical storefronts, reduces overhead costs associated with maintaining a physical space, and minimizes expenses related to inventory management.",
                  benefitsImage04:"./images/serviceImg04.png",
                  
                  benefitsDesHead05:"Global Reach",
                  benefitsDesText05:"E-commerce eliminates geographical barriers, allowing businesses to reach a global customer base. Customers can access your online store from anywhere, at any time,",
                  benefitsImage05:"./images/serviceImg05.png",

                 //Features-List
                  FeatureHead:"Features That Will Help Turn Your Visitors Into Clients",

                   FeatureIcon01:"./images/featureIcon01.png",
                   FeatureSubHead01:"Contact Information",
                   FeatureText01:"Make it simple for visitors to get in touch with your team. Provide clear contact information or a contact form that's easy to find.",

                  FeatureIcon02:"./images/featureIcon02.png",
                  FeatureSubHead02:"Testimonials and Reviews",
                  FeatureText02:"Display real customer testimonials and case studies that highlight successful experiences with your product or service. This builds credibility and trust.",

                  FeatureIcon03:"./images/featureIcon03.png",
                  FeatureSubHead03:"Calls To Action",
                  FeatureText03:"Include prominent and persuasive CTAs throughout your web app. Whether it's signing up for a trial, requesting a demo, or making a purchase, CTAs should stand out and guide visitors towards taking action.",

                  FeatureIcon04:"./images/featureIcon04.png",
                  FeatureSubHead04:"Security Features",
                  FeatureText04:"Display security badges, privacy policies, and SSL certificates to assure visitors that their data is safe and that your business is trustworthy.",

                  FeatureIcon05:"./images/featureIcon05.png",
                  FeatureSubHead05:"Shopping Cart",
                  FeatureText05:"Enable users to save items in a wishlist and easily add products to their shopping carts. This encourages them to revisit and complete their purchases.",

                  FeatureIcon06:"./images/featureIcon06.png",
                  FeatureSubHead06:"Related Items",
                  FeatureText06:" Implement an intelligent recommendation system that suggests related or complementary products based on customers' browsing and purchasing history.",

                 FeatureIcon07:"./images/featureIcon07.png",
                 FeatureSubHead07:"Blog Page",
                 FeatureText07:"Maintain a blog or resources section with valuable content that positions your business as an industry thought leader. This can attract and retain visitors who are seeking information.",

                 FeatureIcon08:"./images/featureIcon08.png",
                 FeatureSubHead08:"Email Subscription Box",
                 FeatureText08:"The loading speed of the website is the first impression of the brand, so make sure it’s a positive one with our best optimized and in flash loading website.",

                 FeatureIcon09:"./images/featureIcon09.png",
                 FeatureSubHead09:"Photo Of You And Your Team",
                 FeatureText09:"Showcase user-generated content, such as customer photos using your products, to build credibility and social proof.",

                //Design-Process
                DesignProcessHead:"WEB APP DESIGN PROCESS",
                DesignProcessSub:"Brings Your Business Online in 7 Easy Steps",
                DesignProcessIcon:"./images/DesignProcessIcon.png",
                

                
                StepNumber01:"1",
                StepHead01:"Fill the contact form",
                StepDes01:"Fill the contact form & within 24Hrs WCS member will contact you.",
                StepIcon01:"./images/StepIcon01.png",

                StepNumber02:"2",
                StepHead02:"Initial consultation",
                StepDes02:"WCS member will note your business requirements, objectives and schedule meeting.",
                StepIcon02:"./images/StepIcon02.png",

                StepNumber03:"3",
                StepHead03:"Requirements gathering ",
                StepDes03:"WCS member will gather all the detailed requirements for your web app, this involves identifying the target audience, defining user personas, outlining features and functionalities, and determining the technical specifications and integrations required.",
                StepIcon03:"./images/StepIcon03.png",

                StepNumber04:"4",
                StepHead04:"Wireframing and prototyping",
                StepDes04:"WCS will create wireframes and prototypes to visualize the user interface and functionality of your web app.",
                StepIcon04:"./images/StepIcon04.png",

                StepNumber05:"5",
                StepHead05:"Development and testing",
                StepDes05:"Once the design is approved,WCS will start the development process. Testing should be conducted at various stages to ensure the web app functions correctly and is free of bugs.",
                StepIcon05:"./images/StepIcon05.png",

                StepNumber06:"6",
                StepHead06:"Deployment and hosting",
                StepDes06:"When development is complete, WCS will deploy the web app to a hosting environment that suits your needs. They will configure servers, set up databases, and ensure the app is accessible to users securely.",
                StepIcon06:"./images/StepIcon06.png",

                StepNumber07:"7",
                StepHead07:"User acceptance testing (UAT) & Launching",
                StepDes07:"Before launching the web app to the public, we will conduct UAT to validate its performance, usability, and overall user experience. After successfully completing UAT, we can launch your web app to the public.",
                StepIcon07:"./images/StepIcon07.png",

                StepNumber08:"8",
                StepHead08:"Launch and marketing",
                StepDes08:"After successfully completing UAT, we will launch your web app to the public. Create a marketing plan to generate awareness and drive traffic to your web app.",
                StepIcon08:"./images/StepIcon08.png",

                StepNumber09:"9",
                StepHead09:"Ongoing maintenance and support",
                StepDes09:"A web app requires continuous maintenance, updates, and security monitoring. Work with WCS to establish a maintenance plan and support agreement to ensure your web app remains secure, up-to-date, and performs optimally.",
                StepIcon09:"./images/StepIcon09.png",
           

                   
            }
        }
    )
  }

 //...PORTFOLIO_PAGE...
  const updatePortfolioPage = () => {
    return dispatch(
        {
            type:"PORTFOLIO_UPDATE",
            payload: {

                //Navbar
                LogoWcs:"./images/LogoWcs.png",

                  //Hero-Image-Section
                  heroImg:"./images/PortfolioHeroImage.png",
                  heroHeading: "Our Work",
                  heroPara:"Partner with Us for Next-Level Web Strategies and Results",

                //OUR-WORK
                BusinessCategoryScreen01:"./images/BusinessCatScreen1.png",
                BusinessCategory01:"Architectural Consultancy",

                BusinessCategoryScreen02:"./images/BusinessCatScreen2.png",
                BusinessCategory02:"Builders & Developers",

                BusinessCategoryScreen03:"./images/BusinessCatScreen3.png",
                BusinessCategory03:"Interior Desigers",

                BusinessCategoryScreen04:"./images/BusinessCatScreen4.png",
                BusinessCategory04:"Photographers & Cinematographers",

                BusinessCategoryScreen05:"./images/BusinessCatScreen5.png",
                BusinessCategory05:"Structural Consultancy",

                BusinessCategoryScreen06:"./images/BusinessCatScreen6.png",
                BusinessCategory06:"HVAC Consultancy",

                BusinessCategoryScreen07:"./images/BusinessCatScreen7.png",
                BusinessCategory07:"BIM Consultancy",

                BusinessCategoryScreen08:"./images/BusinessCatScreen8.png",
                BusinessCategory08:"Hospital",

                BusinessCategoryScreen09:"./images/BusinessCatScreen9.png",
                BusinessCategory09:"Beautician & MUA",

                BusinessCategoryScreen10:"./images/BusinessCatScreen10.png",
                BusinessCategory10:"Electrical Consultancy",

                BusinessCategoryScreen11:"./images/BusinessCatScreen11.png",
                BusinessCategory11:"Lawyers & Law Firms",

                BusinessCategoryScreen12:"./images/BusinessCatScreen12.png",
                BusinessCategory12:"Vastu Consultants",

                BusinessCategoryScreen13:"./images/BusinessCatScreen13.png",
                BusinessCategory13:"Gyms & Gym Trainers",

                BusinessCategoryScreen14:"./images/BusinessCatScreen14.png",
                BusinessCategory14:"Plumbing Consultants",

                BusinessCategoryScreen15:"./images/BusinessCatScreen15.png",
                BusinessCategory15:"Real Estate Agents",

                BusinessCategoryScreen16:"./images/BusinessCatScreen16.png",
                BusinessCategory16:"Event Manangement Consultancy",

                BusinessCategoryScreen17:"./images/BusinessCatScreen17.png",
                BusinessCategory17:"Coaches & Coaching Institutes",

                BusinessCategoryScreen18:"./images/BusinessCatScreen18.png",
                BusinessCategory18:"Dietician & Nutritionists",

                BusinessCategoryScreen19:"./images/BusinessCatScreen19.png",
                BusinessCategory19:"Landscaping Consultants",

                BusinessCategoryScreen20:"./images/BusinessCatScreen20.png",
                BusinessCategory20:"Modular Kitchen Suppliers",


                EcomCategory01:"Clothing & Appareal Stores",
                ecomCategoryScreen01a:"./images/EcomCatScreen01a.png",
                ecomCategory01a:"Men's Clothing Store",
                ecomCategoryScreen01b:"./images/EcomCatScreen01b.png",
                ecomCategory01b:"Women's Clothing Store",
                ecomCategoryScreen01c:"./images/EcomCatScreen01c.png",
                ecomCategory01c:"Kid's Clothing Store",

                EcomCategory02:"Electronics Stores",
                ecomCategoryScreen02a:"./images/EcomCatScreen02a.png",
                ecomCategory02a:"Mobile Store",
                ecomCategoryScreen02b:"./images/EcomCatScreen02b.png",
                ecomCategory02b:"Computer/Laptop Store",
                ecomCategoryScreen02c:"./images/EcomCatScreen02c.png",
                ecomCategory02c:"Cameras & Camera Accessories Store",
                ecomCategoryScreen02d:"./images/EcomCatScreen02d.png",
                ecomCategory02d:"Home Appliance Store",
                ecomCategoryScreen02e:"./images/EcomCatScreen02e.png",
                ecomCategory02e:"Lights & Chandlier Store",
                ecomCategoryScreen02f:"./images/EcomCatScreen02f.png",
                ecomCategory02f:"Machinery Store",

                EcomCategory03:"Fashion Accessories Stores",
                ecomCategoryScreen03a:"./images/EcomCatScreen03a.png",
                ecomCategory03a:"Jewellery Store",
                ecomCategoryScreen03b:"./images/EcomCatScreen03b.png",
                ecomCategory03b:"Perfume Store",
                ecomCategoryScreen03c:"./images/EcomCatScreen03c.png",
                ecomCategory03c:"Bag Store",
                ecomCategoryScreen03d:"./images/EcomCatScreen03d.png",
                ecomCategory03d:"Watch Store",
                ecomCategoryScreen03e:"./images/EcomCatScreen03e.png",
                ecomCategory03e:"Belt & Wallet Store",
                ecomCategoryScreen03f:"./images/EcomCatScreen03f.png",
                ecomCategory03f:"Cosmetics Store",
                ecomCategoryScreen03g:"./images/EcomCatScreen03f.png",
                ecomCategory03g:"Optical Store",

                EcomCategory04:"Footwear Stores",
                ecomCategoryScreen04a:"./images/EcomCatScreen04a.png",
                ecomCategory04a:"Women's Footwear Store",
                ecomCategoryScreen04b:"./images/EcomCatScreen04b.png",
                ecomCategory04b:"Men's Footwear Store",
                ecomCategoryScreen04c:"./images/EcomCatScreen04c.png",
                ecomCategory04c:"Kid's Footwear Store",

                EcomCategory05:"Home Decor Stores",
                ecomCategoryScreen05a:"./images/EcomCatScreen05a.png",
                ecomCategory05a:"Furniture Store",
                ecomCategoryScreen05b:"./images/EcomCatScreen05b.png",
                ecomCategory05b:"Candle Store",
                ecomCategoryScreen05c:"./images/EcomCatScreen05c.png",
                ecomCategory05c:"Flower Vase Store",
                ecomCategoryScreen05d:"./images/EcomCatScreen05d.png",
                ecomCategory05d:"Home Decor Store",

                EcomCategory06:"Automobile Stores",
                ecomCategoryScreen06a:"./images/EcomCatScreen06a.png",
                ecomCategory06a:"Spare Parts Store",
                ecomCategoryScreen06b:"./images/EcomCatScreen06b.png",
                ecomCategory06b:"Accessories/Gears Store",

                EcomCategory07:"Food Products Stores",
                ecomCategoryScreen07a:"./images/EcomCatScreen07a.png",
                ecomCategory07a:"Chocolate Store",
                ecomCategoryScreen07b:"./images/EcomCatScreen07b.png",
                ecomCategory07b:"Tea & Coffee Store",
                ecomCategoryScreen07c:"./images/EcomCatScreen07b.png",
                ecomCategory07c:"Spices Store",
                ecomCategoryScreen07d:"./images/EcomCatScreen07d.png",
                ecomCategory07d:"Pickle Store",

                EcomCategory08:"Gift Box & Art Stores",
                ecomCategoryScreen08a:"./images/EcomCatScreen08a.png",
                ecomCategory08a:"Gift Box Store",
                ecomCategoryScreen08b:"./images/EcomCatScreen08b.png",
                ecomCategory08b:"Art Store",
                

            }
        }
    )
  }

 //...BLOG_PAGE...
 const updateBlogPage = () => {
    return dispatch(
        {
            type:"BLOG_UPDATE",
            payload: {

                //Navbar
                LogoWcs:"./images/LogoWcs.png",

                  //Hero-Image-Section
                  heroImg:"./images/BlogHeroImage.png",
                  heroHeading: "Blog",
                  heroPara:"What We've Got For you",
            }
        }
    )
  }


 //...ABOUT_PAGE...
    const updateAboutPage = () => {
        return dispatch(
            {
                type:"ABOUT_UPDATE",
                payload: {

                    //Navbar
                    LogoWcs:"./images/LogoWcs.png",

                     //Hero-Image-Section
                     heroImg:"./images/AboutHeroImage.png",
                     heroHeading: "About Us",
                     heroPara:"Your Trusted Web Consultancy Partner",
                     
                     
                     subHeroHeading:"Welcome to Web Consultancy Services",
                     subHeroPara:"your premier destination for comprehensive and innovative web consultancy services. With a passion for technology and a commitment to excellence, we are here to transform your digital presence and empower your online success.",                  
                     image1:"./images/subHeroAboutImage.png",

                     //ABOUT-US-SECTION
                     AboutHead:"Welcome to Web Consultancy Services",
                     AboutPara:"your premier destination for comprehensive and innovative web consultancy services. With a passion for technology and a commitment to excellence, we are here to transform your digital presence and empower your online success.",
                     AboutHeroImg:"./images/AboutHeroImg02.png",

                    AboutCardHead01:"Who We Are ",
                    AboutCardPara01:"At WCS, we are a dynamic team of experienced professionals with a shared goal: to help businesses of all sizes navigate the complexities of the digital landscape. Our diverse skill set encompasses web design, development, e-commerce solutions, and more. With a finger on the pulse of the ever-evolving web industry, we stay ahead of trends and technologies to provide you with cutting-edge solutions.",
                    AboutCardHead02:"Our Mission",
                    AboutCardPara02:"Our mission is to empower businesses to thrive in the digital realm by delivering tailored web consultancy services that drive growth, enhance user experiences, and foster lasting connections. We believe that a strategic online presence is not just about aesthetics, but also about functionality, accessibility, and engaging storytelling.",

                    AboutFeatureHead:"Why Choose Us",
                    FeatureHead01:"Expertise",
                    AboutFeaturePara01:"Our team comprises seasoned experts in various domains of web development. We bring a wealth of knowledge and hands-on experience to every project.",
                    FeatureHead02:"Tailored Solutions",
                    AboutFeaturePara02:"We understand that every business is unique. That's why we work closely with you to create customized strategies that align with your specific goals and target audience.",
                    FeatureHead03:"Innovation",
                    AboutFeaturePara03:"The digital landscape is always changing, and we thrive on innovation. We're not afraid to explore new technologies and creative approaches to give you a competitive edge.",
                    FeatureHead04:"Transparency",
                    AboutFeaturePara04:"Communication is key. Throughout every phase of our partnership, we maintain open and transparent communication, keeping you informed about project progress and milestones.",
                    FeatureHead05:"Collaboration",
                    AboutFeaturePara05:"We believe in the power of collaboration. We see ourselves as an extension of your team, working together to achieve remarkable outcomes.",
                    FeatureHead06:"Results-Driven",
                    AboutFeaturePara06:"Your success is our success. We are dedicated to delivering tangible results that contribute to your business growth and objectives.",

                    ProfileHead01:"Founder",
                    ProfileImg01:"./images/ProfileImg01.png",
                    ProfileName01:"Aniket Shahare",
                    ProfileHead02:"Co-Founder",
                    ProfileImg02:"./images/ProfileImg02.png",
                    ProfileName02:"Ankita Bandre",
                }
            }
        )
    }
    
 //...CONTACT_PAGE...
 const updateContactPage = () => {
    return dispatch(
        {
            type:"CONTACT_UPDATE",
            payload: {

                //Navbar
                LogoWcs:"./images/LogoWcs.png",
                
                 //Hero-Image-Section
                 ContactHeroImg:"./images/ContactHeroImage.png",

                 //Contact Form
                 ContactFormHead:"Complete the form  below to tell us about your business. We will get in touch with you as soon as possible",
                 ContactFormIcon:"./images/ContactFormIcon.png",
                 
                 
                 
              
            }
        }
    )
}


     return <AppContext.Provider value={{ ...state, updateHomePage,updateServicesPage, updatePortfolioPage, updateBlogPage, updateAboutPage, updateContactPage}}> {children} </AppContext.Provider>
}; 



//global custom hook
const useGlobalContext = () => {
    return useContext(AppContext);
}

export {AppContext,AppProvider, useGlobalContext};