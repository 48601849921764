import React, { useEffect } from "react";
import { useGlobalContext } from "../context";
import HeroImageSection from "../Components/HeroImageSection";
import ReachOut from "../Components/ReachOut";
import OurWork from "../Components/OurWork";
import Navbar from "../Components/Navbar";


const Portfolio = () => {

  const {updatePortfolioPage} = useGlobalContext();

  useEffect(() => updatePortfolioPage(), []);

  return(
    <>
    {/* <Navbar/> */}
      <HeroImageSection/>
      <OurWork/>
      <ReachOut/>
    </>
  )
}

export default Portfolio;