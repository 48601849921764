import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {CgMenu, CgCloseO} from "react-icons/cg"

const NavBarNew = () => {

  const [openMenu, setOpenMenu] = useState(false);

  const Wrapper = styled.nav`
  .navbar-list {
    display:flex;
    gap:5rem;

    li {
      list-style:none;

        .navbar-link {
          &:link,
          &:visited {
            display:inline-block;
            text-decoration:none;
            font-size:2.2rem;
            font-weight:500;
            ${'' /* text-transform: uppercase; */}
            color: ${({ theme }) => theme.colors.black};
            transition: color 0.3s linear;
          }

        &:hover,
        &:active {
          color:${({ theme }) => theme.colors.helper};
        }
      }

    }
  }

  .mobile-navbar-btn {
    display:none;

    .close-outline {
      display:none;
    }
  }

  .mobile-navbar-btn[name="close-outline"] {
    display:none;
  }

  //998px
@media (max-width:${({ theme }) => theme.media.tab}) {
  .navbar-list {
    gap:3rem;
  }
}

  //768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
  .mobile-navbar-btn {
    display:inline-block;
    z-index:999;
    border: ${({ theme }) => theme.colors.black};

    .mobile-nav-icon {
      font-size: 4.2rem;
      color: ${({ theme }) => theme.colors.black};
      margin-right:3rem;
    }
  }
 
  //hide original navbar links
  .navbar-list {
    width:100vw;
    height: auto;
    position:absolute;
    top: 90%;
    left:0;
    background-color: ${({ theme }) => theme.colors.bg};
    padding:1rem 1rem;

    display:flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align:center;

    transform:translateX(100%);

  li {
    
    &.navbar-link {
          &:link,
          &:visited {
           
            font-size:4.2rem;
           
          }

        &:hover ,
        &:active {
          color:${({ theme }) => theme.colors.helper};
        }
      }
  }

 
  }

  .active .mobile-nav-icon {
    display:none;
    font-size: 4.2rem;
    position: absolute;
    top:30%;
    right: 10%;
    color:${({ theme }) => theme.colors.black};
    z-index:9999;
  }

  .active .close-outline {
    display: inline-block;
    top:30%;
    right: 0%;
  }

  .active .navbar-list {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
    z-index:999;
    width:95vw;
  }

}
 


`;

    return( <Wrapper>
        <div className={openMenu ? "menuIcon active" : "menuIcon"}>
          <ul className="navbar-list">
           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/">Home</NavLink>
           </li>

           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/services">Services</NavLink>
           </li>

           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/portfolio">Portfolio</NavLink>
           </li>

           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/blog">Blog</NavLink>
           </li>

           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/aboutus">About</NavLink>
           </li>

           <li>
             <NavLink className="navbar-link" onClick={() => setOpenMenu(false)} to="/contactus">Contact</NavLink>
           </li>

          </ul>

          <div className="mobile-navbar-btn">
            <CgMenu name="menu-outline" className="mobile-nav-icon"
              onClick={() => setOpenMenu(true)}
            />
            <CgCloseO name="close-outline" className="close-outline mobile-nav-icon"
              onClick={() => setOpenMenu(false)}
            />
          </div>

        </div>
    </Wrapper>

)


};

export default NavBarNew;