import React,{useContext}  from "react";
import { styled } from "styled-components";
import {  useGlobalContext } from "../context";



const HeroSection = () => {

    const {subHeroHeading,subHeroPara, image1, image2,image3} = useGlobalContext();

  return(
    <Wrapper>
    <div className="hero-section  ">

        <div className="section-hero-data">
            
            <h1 className="hero-heading">{subHeroHeading}</h1>
            <p className="hero-para">{subHeroPara}</p>
          
        </div>

        <div className="section-hero-image">
            
               <img src={image1} alt="hero-img" className="hero-img1" />
               <img src={image2} alt="hero-img" className="hero-img2" />
               <img src={image3} alt="hero-img" className="hero-img3" />
            
       </div>
    </div>

    </Wrapper>
    
  )
}



const Wrapper = styled.section`
  .hero-section {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    margin-top:-1rem;
    padding: 3rem 5rem;
    
    
    .section-hero-data {
        display:flex;
        flex-direction: column;
        align-items:flex-start;
        justify-content: center;
        margin-left:10rem;

        h1 {
            font-size: 6rem;
            width: 60%;
        }

        p {
            
            font-size: 2rem;
            width: 60%;
            
        }
    }

    .section-hero-image {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction:column; 
        position:relative;
        margin-top: 4rem;
        margin-right:10rem;
        margin-left:-7rem;

        .hero-img1  {
            position:absolute;
            z-index:99;
        }

        .hero-img2 {
          position:absolute;
        }
    }
  }


//998px
@media (max-width:${({ theme }) => theme.media.tab})   {
    .hero-section {
        padding: 2rem 3rem;

        .section-hero-data {
            margin-left:5rem;
            margin-top:3rem;
            h1 {
                ${'' /* font-size: 4rem; */}
                width: 80%;
            }
            p {
                font-size: 2rem;
                width: 80%;
            }
        }

        .section-hero-image {
            margin-right:7rem;

        }

    }
}


//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
   
    .hero-section {
        flex-direction: column;
        align-items:center;
        margin-top:-13rem;

        .section-hero-data  {
            margin-left:1rem;
            margin-top:1rem;
            align-items:center;

            h1 {
                font-size: 4rem;
                width: 100%;
                text-align:center;
            }
            p {
                font-size:2.5rem;
                width: 100%;
                text-align:center;
            }
        }
        .section-hero-image {
            margin-right:1rem;
            align-items:center;
            padding:4rem 1rem;
            margin-left:1rem;

            .hero-img1 {
                width:70%;
            }
            .hero-img2 {
                width:70%;
            }
            .hero-img3 {
                width:70%;
            }
        }
    }
    
}

`;

export default HeroSection;