import React from "react";
import { useGlobalContext } from "../context";
import { styled } from "styled-components";

const Benefits = () =>  {
    const {benefitsHead, 
        benefitsSubHead, 

        benefitsDesHead01, 
        benefitsDesText01, 
        benefitsImage01, 

        benefitsDesHead02, 
        benefitsDesText02, 
        benefitsImage02, 

        benefitsDesHead03, 
        benefitsDesText03, 
        benefitsImage03, 

        benefitsDesHead04, 
        benefitsDesText04, 
        benefitsImage04, 
        
        benefitsDesHead05, 
        benefitsDesText05, 
        benefitsImage05  
    
    } = useGlobalContext();

    return(
        <Wrapper>

            <div className="benefits ">

                <div className="benefits-head">
                    <p>{benefitsHead}</p>
                    <h1>{benefitsSubHead}</h1>
                </div>


                <div className="benefits-list">

                    <div className="benefits-des-text">
                        <h2>{benefitsDesHead01}</h2>
                        <p>{benefitsDesText01}</p>
                    </div>

                    <div className="benefits-img">
                        <img alt="img" src={benefitsImage01} ></img>
                    </div>

                   
                </div>

                <div className="benefits-list-reverse">

                    <div className="benefits-des-text">
                        <h2>{benefitsDesHead02}</h2>
                        <p>{benefitsDesText02}</p>
                    </div>

                    <div className="benefits-img">
                        <img alt="img" src={benefitsImage02} ></img>
                    </div>

                </div>

                <div className="benefits-list">

                    <div className="benefits-des-text">
                        <h2>{benefitsDesHead03}</h2>
                        <p>{benefitsDesText03}</p>
                    </div>

                    <div className="benefits-img">
                        <img alt="img" src={benefitsImage03} ></img>
                    </div>

                   
                </div>

                <div className="benefits-list-reverse">

                    <div className="benefits-des-text">
                        <h2>{benefitsDesHead04}</h2>
                        <p>{benefitsDesText04}</p>
                    </div>

                    <div className="benefits-img">
                        <img alt="img" src={benefitsImage04} ></img>
                    </div>

                </div>

                
                <div className="benefits-list">

                    <div className="benefits-des-text">
                        <h2>{benefitsDesHead05}</h2>
                        <p>{benefitsDesText05}</p>
                    </div>

                    <div className="benefits-img">
                        <img alt="img" src={benefitsImage05} ></img>
                    </div>

                   
                </div>

            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`

.benefits {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    text-align: center;
    margin-top:-1rem;
    width:100%;

    .benefits-head {
         display:flex;
         flex-direction: column;
         align-items: center;
         margin-top: 2rem;

         
       p {
            font-size: 2rem;
            font-weight: 500;
         }
       h1 {
            width:100%;
            font-size: 4rem;
            text-align: center;
            margin-top: 3rem;
          }


       } 

    .benefits-list {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding:3rem 20rem;

        .benefits-des-text {
        width:45%;
       
           h2 {
            font-size: 3rem;
            text-align:right;
           }

           p {
            font-size: 2rem;
            text-align:right;
          }
        }

        .benefits-img {
        margin-right:7rem;
      }
    
    }

    .benefits-list-reverse {
       display:flex;
       flex-direction: row-reverse;
       justify-content: space-between;
       align-items: center;
       padding:1rem 20rem;
    

        .benefits-des-text {
         width:45%;
       

          h2 {
            font-size: 3rem;
            text-align:left;
          }

          p {
            font-size: 2rem;
            text-align:left;
          }
        }

       .benefits-img {
        margin-left:7rem;
    }
   
   }


}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .benefits {
        margin-top:-2rem;
        .benefits-head {
            p {
                
            }
            h1 {
                width:100%;
            }
        }

        .benefits-list {
            padding:1rem 7rem;

            .benefits-des-text {
                h2 {


                 }

               p {
                    font-size: 2rem;
                } 

            }

          

        }

        .benefits-list-reverse {
            padding:1rem 7rem;

            .benefits-des-text {
                h2 {


                 }

               p {
                    font-size: 2rem;
                } 

            }
        }
    }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
    .benefits {
        margin-top: -13rem;
        margin-bottom:0rem;

        .benefits-head {
            margin-top: 1rem;
            p {
                font-size: 2rem;
                font-weight: 520;
            }

            h1 {
            width: 95%;
            font-size: 3rem;
            margin-top: 1rem;
            } 
        }

        .benefits-list {
            display:flex;
            flex-direction: column-reverse;
            padding:0rem 0rem;
            margin-top: 5rem;

            .benefits-des-text {
                width:95%;
                margin-top:1rem;
                
                h2 {
            font-size: 2.5rem;
            text-align:left;
            font-weight:550;
           }

           p {
            font-size: 2rem;
            text-align:left;
          }
            }

            .benefits-img {

               img {
                width:30rem;
               }
      }
        }

        .benefits-list-reverse {
            display:flex;
            flex-direction: column-reverse;
            padding:0;
            margin-top: 3rem;
           

            .benefits-des-text {
                width:95%;
                margin-top:1rem;
                
                h2 {
            font-size: 2.5rem;
            text-align:left;
            font-weight:550;
           }

           p {
            font-size:2rem;
            text-align:left;
            
          }
            }

            .benefits-img {
                margin:0;

               img {
                width:30rem;
               }
      }
        }


    }

}





`;

export default Benefits;