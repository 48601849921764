import React, { useEffect } from "react";
import { useGlobalContext } from "../context";
import HeroImageSection from "../Components/HeroImageSection";
import ReachOut from "../Components/ReachOut";
import Navbar from "../Components/Navbar";
import BlogPage from "../Components/BlogPage";


const Blog = () => {

  const {updateBlogPage} = useGlobalContext();

  useEffect(() => updateBlogPage(), []);

  return(
    <>
    {/* <Navbar/> */}
      <HeroImageSection/>
      <BlogPage/>
      <ReachOut/>
    </>
  )
}

export default Blog;