import React, {useRef} from "react";

import { styled } from "styled-components"; 

import { useGlobalContext } from "../context";
import emailjs from '@emailjs/browser';

const ReachOut = () => {
    const{LogoWcs}= useGlobalContext();

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
        'service_kxk2ji7', 
        'template_qxj30jb', 
        form.current, 
        'z6T6q0C-_6_fTIHMf'
        )
      .then((result) => {
          console.log(result.text);
          console.log("Meassage Sent");
      }, (error) => {
          console.log(error.text);
      });
  };
    return(
<Wrapper>
    <div className="reachOut-section">

        <div className="reach-out">

            <div className="reachOut-text">
             <img alt="img"  src={LogoWcs}></img>
             <p className="reachOut-head">Reach Out</p>
             <h1>Ready to Build Something Great?</h1>
             <p>Partner with us to develop technolgy to grow your business.</p>
            </div>

            <form ref={form} onSubmit={sendEmail} className="reach-form">
              <input type="text" name="user_name" placeholder="Name"/>
              <input type="text" name="company_name" placeholder="Company Name"/>
              <input type="email" name="user_email" placeholder="Email"/>
              <input type="number" name="user_mobile" placeholder="Mobile Number"/>
              <textarea name="message" placeholder="How can we help you?" rows={4}/>
              <input type="submit" value="Send" className="contact-send" />
           </form>
   
       </div>

    </div>
</Wrapper>
    )
}


const Wrapper = styled.section`

.reachOut-section {
    background-color: ${({theme}) => theme.colors.darkBlack};

    .reach-out {
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    align-items: center;
    border: 1px solid ${({theme}) => theme.colors.white};
    border-radius: 10px;
    margin:0rem 7rem; 
    transform:translateY(7rem);
    background-color: ${({theme}) => theme.colors.bg};
    

    .reachOut-text {
      width:100%;
      display: flex;
      flex-direction:column;
      align-items:center;
      gap:1rem;  
    }

    img {
        height:auto;
        max-width:6.5rem;
        margin-top: -1rem;
    }

    .reachOut-head {
        font-size: 2rem;
        color:  ${({ theme }) => theme.colors.black};

    }

    .reachOut-text h1 {
        font-size: 3.5rem;
        text-align:center;
        width:50%;
        color:  ${({ theme }) => theme.colors.black};
    }

    .reachOut-text p {
        font-size: 2.2rem;
        text-align:center;
        width:90%;
        color:  ${({ theme }) => theme.colors.black};
    }

    .reach-form {
        display:flex;
        flex-direction:column;
        align-items:center;
        width:100%;
        padding:8rem 2rem;
        
    }

    .reach-form input {
        width:95%;
        height: 5rem;
        padding: 0 1rem;
        margin-bottom: 1rem;
        border-radius: 1.5rem;
        border: 1px solid ${({ theme }) => theme.colors.black};
        font-size: 1.7rem;
        
    }

    .reach-form textarea {
        width:95%;
        padding: 0 1rem;
        margin-bottom: 1rem;
        border-radius: 1.5rem;
        border: 1px solid ${({ theme }) => theme.colors.black};
        font-size: 1.7rem;

    }

    .contact-send {
            background-color: ${({ theme }) => theme.colors.blue};
            width:20%;
            margin-top:1rem;
            padding:0.5rem 0.5rem;
            font-size:1.5rem;
            border-radius:1rem;
            cursor:grab;
            color: ${({ theme }) => theme.colors.white};
            transition: all 0.3s ease 0s;

            -webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;

            &:hover {
              background-color: ${({ theme }) => theme.colors.green};
              
              


            }
          }
}

}


//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .reachOut-section {
    margin-top:-1.5rem;
    .reach-out {
        flex-direction:row;
        margin:0rem 5rem;
        

        .reachOut-text {
            margin-top:-5rem;
            gap:0.75rem;

            .reachOut-head {
                font-weight:550;
               
            }

            h1 {
                font-size: 3rem;
                width:80%;
            }

            p {
                font-size: 2rem;
            }
        }

        .reach-form {
            margin-top:-3rem;

            
        }


        
    }   
}
}


//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
    .reachOut-section {
    margin-top:-2rem;
    .reach-out {
        flex-direction:column;
        margin:0rem 3rem;
        

        .reachOut-text {
            margin-top:2rem;
            gap:0.75rem;

            .reachOut-head {
                font-size: 2.5rem;
                font-weight:550;
                
            }

            h1 {
                font-size: 4rem;
                width:80%;
            }

            p {
                font-size: 3rem;
            }
        }

        .reach-form {
            margin-top:-3rem;
            input {
                font-size: 2rem;
            }

            textarea {
                font-size: 2rem;
            }
            

            
        }


        
    }   
}
}

`;

export default ReachOut;