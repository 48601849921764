import React from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { styled } from "styled-components";
import { useGlobalContext } from "../context";
import NavBarNew from "./NavBarNew";

const Header = () => {

const {LogoWcs} = useGlobalContext();

  return(
       
    <MainHeader>
      <NavLink to="/" className="logo-name">
         <img src={LogoWcs} alt="logo"  className="logo-wcs"/>
         <h1 className="companyName">WCS</h1>
      </NavLink>

      <NavBarNew/>
    

      {/* <Navbar/> */}

    </MainHeader>

    
  )
}


const MainHeader = styled.header`
padding: 0 3rem;
box-shadow: ${({theme})  => theme.colors.shadow};
height: 8rem;
background-color: ${({ theme }) => theme.colors.bg};
display: flex;
justify-content: space-between;
align-items:center;
width:95%;
position:fixed;
margin: 2.5rem 3rem;
border-radius: 1.3rem;
opacity:90%;
z-index:9999;


.logo-name {
 display:flex;
 align-items:center;
 gap:1.5rem;
}
.companyName {
 font-size: 4rem;
 color:${({ theme }) => theme.colors.black};
 ${'' /* z-index:999; */}

}

.logo-wcs {
  height: auto;
  max-width: 5.5rem;
}


//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
  margin:1rem;
  width:95%;
  padding: 1rem 1rem;
  height:auto;
}
`;


export default Header;