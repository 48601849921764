import React from "react";
import { useGlobalContext } from "../context";
import { styled } from "styled-components";

const Features = () => {

    const {
        FeatureHead,

        FeatureIcon01,
        FeatureSubHead01,
        FeatureText01,

        FeatureIcon02,
        FeatureSubHead02,
        FeatureText02,

        FeatureIcon03,
        FeatureSubHead03,
        FeatureText03,

        FeatureIcon04,
        FeatureSubHead04,
        FeatureText04,

        FeatureIcon05,
        FeatureSubHead05,
        FeatureText05,

        FeatureIcon06,
        FeatureSubHead06,
        FeatureText06,

        FeatureIcon07,
        FeatureSubHead07,
        FeatureText07,

        FeatureIcon08,
        FeatureSubHead08,
        FeatureText08,

        FeatureIcon09,
        FeatureSubHead09,
        FeatureText09,


    } = useGlobalContext();

    return (
        <Wrapper>

          <div  className="features">

            <h2>{FeatureHead}</h2>

            <div className="feature-section">

              <div className="features-list">

               <img alt="img" src={FeatureIcon01}></img>

                <div className="feature-des-text">

                  <h3>{FeatureSubHead01}</h3>
                  <p>{FeatureText01}</p>

                </div>

              </div>

              <div className="features-list">

                <img alt="img" src={FeatureIcon02}></img>

                <div className="feature-des-text">
                    <h3>{FeatureSubHead02}</h3>
                    <p>{FeatureText02}</p>

                </div>

              </div>

              <div className="features-list">

               <img alt="img" src={FeatureIcon03}></img>
 
               <div className="feature-des-text">
                  <h3>{FeatureSubHead03}</h3>
                  <p>{FeatureText03}</p>

                </div>

              </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon04}></img>

                <div className="feature-des-text">
                   <h3>{FeatureSubHead04}</h3>
                   <p>{FeatureText04}</p>

                </div>

             </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon05}></img>

               <div className="feature-des-text">
                  <h3>{FeatureSubHead05}</h3>
                  <p>{FeatureText05}</p>

                </div>

             </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon06}></img>

                <div className="feature-des-text">
                 <h3>{FeatureSubHead06}</h3>
                  <p>{FeatureText06}</p>

                </div>

             </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon07}></img>

                <div className="feature-des-text">
                  <h3>{FeatureSubHead07}</h3>
                  <p>{FeatureText07}</p>

                </div>

             </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon08}></img>

                <div className="feature-des-text">
                   <h3>{FeatureSubHead08}</h3>
                   <p>{FeatureText08}</p>

                </div>

             </div>

             <div className="features-list">

              <img alt="img" src={FeatureIcon09}></img>

                <div className="feature-des-text">
                  <h3>{FeatureSubHead09}</h3>
                  <p>{FeatureText09}</p>

                </div>

             </div>

            </div>

            

          </div>

        </Wrapper>
    )

}

const Wrapper = styled.section`

  .features {
    background-color: ${({theme}) => theme.colors.bg};
    padding:3rem 5rem;
    background-color: ${({ theme }) => theme.colors.darkBlack};

    h2 {
        font-size:4rem;
        font-weight:550;   
        
    }

  .feature-section {
    display:grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    padding:4rem 3rem;
    gap:3rem;

    .features-list {
      display:flex;
      flex-direction:row;
      gap:1rem;
      margin-top:1rem;

      img {
        height:6rem;
        width:6rem;
        
      }

      .feature-des-text {
        h3 {
          ${'' /* color: ${({ theme }) => theme.colors.white}; */}
        }
        p {
          width:85%;
          ${'' /* color: ${({ theme }) => theme.colors.white}; */}
        }
      }

    }
    
  }

    
  }


//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
  .features {
    padding:3rem 3rem;
    margin-top:-1rem;
    h2 {
      font-size:3.5rem;
    }

    .feature-section {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      padding:3rem 3rem;

      .features-list {
        gap:1rem;

        img {

        }

        .feature-des-text {
          h3 {

          }
          p {
            width:95%;
          }
        }
      }
    }
  }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
  .features {
    padding:3rem 1rem;
    width:100%;

    h2 {
        font-size:3rem;
        font-weight:550; 
        margin-top:3rem;     
    }

    .feature-section {
      padding:3rem 3rem;
       gap:1rem;
       width:95%;

       .features-list {
        gap:1rem;
      margin-top:2rem;
      width:95%;


      img {
        ${'' /* width:50%; */}
      }

      .feature-des-text  {
        h3 {
          font-size:2.5rem;
        }
        p {
          font-size:2rem;
          width:110%;
          
        }
      }
       }
    }
  }
}

`;

export default Features;