import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    ${'' /* font-family: 'Work Sans', sans-serif; */}
}

html {
    font-size:62.5%;
    overflow-x:hidden;
}

body {
    overflow-x:hidden;
}

h1 {
    color:${({theme}) => theme.colors.white};
    font-size: 7rem;
    font-weight: 550;
}

h2 {
    color: ${({theme}) => theme.colors.white};
    font-size: 4.4rem;
    font-weight: 300;
    white-space:normal;
    text-align: center;
    color: ${({theme}) => theme.colors.white};
}

h3 {
    font-size: 1.8rem;
    font-weight:400;
    color: ${({theme}) => theme.colors.white};
}

p {
    color: ${({theme}) => theme.colors.white};
    font-size: 1.65rem;
    font-weight: 400;
    margin-top:1rem;
}

a {
    text-decoration: none;
    font-size: 2rem;
}

li {
    list-style: none;
    font-size: 2rem;
}

img {
    width:100%;
    height:auto;
}

.container {
    ${'' /* max-width: 120rem; */}
    display:flex;
    flex-direction:row;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.colors.darkBlack};
}

.grid {
    display: grid;
    gap: 9rem;
}

.grid-two-column {
    grid-template-columns: repeat(2, 1fr);
}

.grid-three-column {
    grid-template-columns repeat(3, 1fr);
}

.grid-four-column {
    grid-template-columns: 1fr 1.2fr .5fr .8fr;
}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {

    .container {
        ${'' /* padding: 0 3.2rem; */}
    }

    html {
     font-size:55%;
   
    }   
}


//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
     .container {
        ${'' /* padding: 0 3.2rem; */}
     }

     html {
     font-size:40%;
   
    }
}

`;