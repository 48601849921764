import React from "react";
import { useGlobalContext } from "../context";
import { styled } from "styled-components";

const DesignProcess = () => {
   const {
    //Top
    DesignProcessHead,
    DesignProcessSub,
    DesignProcessIcon,
    

    //Bottom
    StepNumber01,
    StepHead01,
    StepDes01,
    StepIcon01,

    StepNumber02,
    StepHead02,
    StepDes02,
    StepIcon02,

    StepNumber03,
    StepHead03,
    StepDes03,
    StepIcon03,

    StepNumber04,
    StepHead04,
    StepDes04,
    StepIcon04,

    StepNumber05,
    StepHead05,
    StepDes05,
    StepIcon05,

    StepNumber06,
    StepHead06,
    StepDes06,
    StepIcon06,

    StepNumber07,
    StepHead07,
    StepDes07,
    StepIcon07,

    StepNumber08,
    StepHead08,
    StepDes08,
    StepIcon08,

    StepNumber09,
    StepHead09,
    StepDes09,
    StepIcon09,
    
   } = useGlobalContext();

   return(
    <Wrapper>
        <div className="design-process">

            <div className="design-process-top">

                <div className="design-des-text">

                    <p>{DesignProcessHead}</p>
                    <h1>{DesignProcessSub}</h1>

                </div>

                <div className="design-icon">

                   <img alt="icon"  className="design-icon01" src={DesignProcessIcon}></img>
                  
                </div>

            </div>



            <div className="design-process-bottom">

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber01}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead01}</h3>
                            <p>{StepDes01} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon01} ></img>

                        </div>

                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber02}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead02}</h3>
                            <p>{StepDes02} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon02} ></img>

                        </div>

                       


                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber03}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead03}</h3>
                            <p>{StepDes03} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon03} ></img>

                        </div>

                       


                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber04}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead04}</h3>
                            <p>{StepDes04} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon04} ></img>

                        </div>

                       


                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber05}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead05}</h3>
                            <p>{StepDes05} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon05} ></img>

                        </div>

                       


                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber06}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead06}</h3>
                            <p>{StepDes06} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon06} ></img>

                        </div>

                       


                    </div>

                    <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber07}</h3>
                           {/* <div className="step-path">

                             </div> */}
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead07}</h3>
                            <p>{StepDes07} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon07} ></img>

                        </div>

                       


                    </div>

                    {/* <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber08}</h3>
                           <div className="step-path">

                             </div>
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead08}</h3>
                            <p>{StepDes08} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon08} ></img>

                        </div>

                    </div> */}

                    {/* <div className="steps">

                        <div className="step-number">

                            <h3>{StepNumber09}</h3>
                           
                        </div>

                        <div className="steps-text">

                            <h3>{StepHead09}</h3>
                            <p>{StepDes09} </p>

                        </div>

                        <div className="steps-icon">
                            <img alt="steps-icon"  src={StepIcon09} ></img>

                        </div>

                    </div> */}

            </div>
        </div>
    </Wrapper>
   )
}

const Wrapper = styled.section`

 .design-process {
   display:flex;
   flex-direction:column;
   margin-top:-1rem;
   padding:1rem 1rem;
   background-color: ${({ theme }) => theme.colors.darkBlack};


   .design-process-top {
    display:flex;
    flex-direction:row;
    align-items:center;
    
    padding:2rem 1rem;

    .design-des-text {
        display:flex;
        flex-direction:column;
        align-items:center;
        text-align:center;
        ${'' /* gap:2rem; */}

        p {
            font-size: 2rem;
            font-weight: 500;
         }

         h1 {
            width: 70%;
            font-size: 4rem;
            text-align: center;
            margin-top: 3rem;
         }
    }

    .design-icon {
        
        ${'' /* margin-top:4rem; */}

        
    }


   }

   .design-process-bottom {
    display:flex;
    flex-direction:column;
    justify-content:center;
     margin-top:5rem;
     padding:3rem 6rem;
     

     .steps {
        display:flex;
        justify-content:center;
        

        .step-number {
            display:flex;
            flex-direction: column;
            align-items:center;
           

            h3 {
                font-size:3rem;
                font-weight:550;
                border:1px solid;
                padding:2rem 2rem;
                border-radius:50%;
                color:  ${({ theme }) => theme.colors.darkBlack};
                background-color:  ${({ theme }) => theme.colors.white};
            }

            .step-path {
                border:1px solid;
                height:10rem;
                width:0.7rem;
                background-color:  ${({ theme }) => theme.colors.white};
            }
        }

        .steps-text {
            align-text:left;
            margin-left:5rem;
            width:40%;
           
            h3 {
                font-size:2rem;
                font-weight:550;
                align-text:left;
                
            }

            p {
                width:105%;
            }


        }

        .steps-icon {
           

            img {
                width:11rem;
                margin-left:5rem;
                border-radius:50%;
                padding:2rem 2rem;
                background-color:  ${({ theme }) => theme.colors.grey};
            }

        }
     }
   }


 }


 //998px
@media (max-width:${({ theme }) => theme.media.tab})  {

}

 //768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
    .design-process {
        margin-top:-1rem;
        padding:1rem 1rem;
        

        .design-process-top {
            flex-direction:column;
            margin-top:0rem;
            padding:2rem 1rem;

            .design-des-text {
                gap:0rem;

                p {
                    font-size: 2rem;
            color:  ${({ theme }) => theme.colors.white};
                }

                h1 {
                    font-size: 3rem;
                    width: 80%;;
                     
                     color:  ${({ theme }) => theme.colors.white};
                }
            }

            .design-icon {
                
                margin-top:7rem;
                width:30rem;
               
        }
    }    

    .design-process-bottom {
        margin-top:5rem;
        padding:3rem 5rem;
        gap:1rem;
        

        .steps {

            .step-number {
                h3 {
                    font-size:2rem;
                    padding:1rem 1.5rem;
                }

                .step-path {
                    height:13rem;
                }
            }

            .steps-text {
                margin-left:2rem;
                width:100%;
                h3 {
                    font-size:2rem;
                    color:  ${({ theme }) => theme.colors.white}; 
                    width:110%;
                }

                p {
                    font-size:1.5rem;
                width:100%;
                color:  ${({ theme }) => theme.colors.white};
            }

            }

            .steps-icon {
                    img {
                        margin-left:3rem;
                        width:7rem;
                        font-size:3rem;
                        padding:0.7rem 0.7rem;
                    }
            }
        }
    }
}


`;

export default DesignProcess;