import React from "react";
import { useGlobalContext } from "../context";
import styled from "styled-components";

const AboutUsSection = () => {
    const {
        AboutHead,
        AboutPara,
        AboutHeroImg,

        AboutCardHead01,
        AboutCardPara01,
        AboutCardHead02,
        AboutCardPara02,
        
        AboutFeatureHead,
        FeatureHead01,
        AboutFeaturePara01,
        FeatureHead02,
        AboutFeaturePara02,
        FeatureHead03,
        AboutFeaturePara03,
        FeatureHead04,
        AboutFeaturePara04,
        FeatureHead05,
        AboutFeaturePara05,
        FeatureHead06,
        AboutFeaturePara06,

        ProfileHead01,
        ProfileImg01,
        ProfileName01,
        ProfileHead02,
        ProfileImg02,
        ProfileName02,





    } = useGlobalContext();

    return (
        <Wrapper>
            <div className="about-section">

                <div className="about-hero">

                    <div className="des-text">
                       <h3>{AboutHead}</h3>
                        <p>{AboutPara}</p>
                    </div>
                    
                    <div className="about-img">
                        <img alt="aboutImg" src={AboutHeroImg}></img>
                    </div>
                  
                </div>
               
               <div className="about-cards">
                  
                   <div className="about-card">
                      
                      <h3>{AboutCardHead01}</h3>
                      <p>{AboutCardPara01}</p>

                   </div>

                   <div className="about-card">
                      
                      <h3>{AboutCardHead02}</h3>
                      <p>{AboutCardPara02}</p>

                   </div>
                
               </div>

                <div className="about-features">

                  <h2>{AboutFeatureHead}</h2>

                    <div className="feature-section">
                      
                        <div className="about-feature">
                    
                            <h3>{FeatureHead01}</h3>
                            <p>{AboutFeaturePara01}</p>
                        </div>

                        <div className="about-feature">
                    
                           <h3>{FeatureHead02}</h3>
                           <p>{AboutFeaturePara02}</p>
                        </div>

                        <div className="about-feature">
                    
                           <h3>{FeatureHead03}</h3>
                        <p>{AboutFeaturePara03}</p>
                         </div>

                        <div className="about-feature">
                    
                        <h3>{FeatureHead04}</h3>
                        <p>{AboutFeaturePara04}</p>
                        </div>

                        <div className="about-feature">
                    
                        <h3>{FeatureHead05}</h3>
                        <p>{AboutFeaturePara05}</p>
                         </div>
  
                        <div className="about-feature">
                    
                        <h3>{FeatureHead06}</h3>
                        <p>{AboutFeaturePara06}</p>
                         </div>
                       

                    </div>

               </div>

               <div className="about-profile-cards">

                    <div className="profile-card">
                    
                        <h2>{ProfileHead01}</h2>
                   
                        <img alt="profileImg" src={ProfileImg01}></img>

                        <h3>{ProfileName01}</h3>
                    
                    </div>    

                    <div className="profile-card">
                    
                        <h2>{ProfileHead02}</h2>
                   
                        <img alt="profileImg" src={ProfileImg02}></img>

                        <h3>{ProfileName02}</h3>
                    
                    </div>   

                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`
 .about-section {
    background-color: ${({ theme }) => theme.colors.darkBlack};
    margin-top:-1rem;
    
    .about-hero {
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:space-between;
        margin:0rem 3rem;

        .des-text {
            display:flex;
            flex-direction:column;
            align-items:center;
            text-align:center;

            h3 {
                font-size: 3rem;
            }

            p {
                width:70%;
                font-size: 2rem;
            }
        }

        .about-img {
          width:90%;
          margin-right:5rem;
        }
    }

    .about-cards {
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        margin:3rem 7rem;
        gap:11rem;
        

        .about-card {
            display:flex;
            flex-direction:column;
           padding:3rem 3rem;
           width:90%;
           gap:2rem;
            background-color: ${({ theme }) => theme.colors.black};
            border-radius:3rem;

            h3 {
                text-align:left; 
                font-size:3rem; 
            }

            p {
                width:95%;
                font-size:2rem; 
            }
        }

 
    }

    .about-features {
            display:flex;
            flex-direction:column;
            margin:7rem 10rem;

            h2 {
                font-size: 5rem;
                font-weight:500;
            }

            .feature-section {
                display:grid;
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                margin-top:7rem;
                gap:5rem;

                .about-feature {
                    h3 {
                        font-size:2rem;
                    }

                    p {
                        width:80%;
                    }
                }
            }
        }

        .about-profile-cards {
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
            gap:15rem;

            .profile-card {
                display:flex;
                flex-direction:column;
                align-items:center;
                padding:2rem 1rem;
                ${'' /* background-color: ${({ theme }) => theme.colors.bg}; */}
                border-radius:1rem;

                h2 {
                    font-size:3rem;
                    margin-bottom:1rem;
                    ${'' /* color: ${({ theme }) => theme.colors.white}; */}
                }

                img {
                    width:25rem;
                    border:1px solid ${({ theme }) => theme.colors.black};
                    padding:1rem;
                    border-radius:1rem;
                }

                h3 {
                    font-size:2.5rem;
                    margin-top:1rem;
                    ${'' /* color: ${({ theme }) => theme.colors.white}; */}
                }


            }

        }
 }


 //998px
@media (max-width:${({ theme }) => theme.media.tab}) {

    .about-section {
        margin-top:-2rem;
        .about-hero {
           
            
            .des-text {
                h3 {
                    margin-top:3rem;

                }
                p {

                }
            }
        }

        .about-cards {
            margin:3rem 5rem;
            gap:7rem;

            .about-card {
                gap:0rem;
                h3 {

                }
                p {
                    width:100%;
                }
            }
        }

        .about-features {
            margin:5rem 7rem;

            h2 {
                font-size: 4.5rem;
            }

            .feature-section {
                margin-top:3rem;
                gap:3rem;

                .about-feature {
                    h3 {
                        font-size:2.5rem;
                    }
                    p {

                    }
                }
            }


        }
    }
}


 //768px
@media (max-width:${({ theme }) => theme.media.mobile})  {
    .about-section  {
       
        margin-top:-13rem;

        .about-hero  {
            flex-direction:column;
            

            .des-text {
                margin-top:3rem;
                h3 {
                   
                    font-size: 3rem;
                    text-align:left;
            }

            p {
                width:95%;
                font-size: 2rem;
                text-align:left;
            }
            }

            .about-img {
                img {
                    width:30rem;
                    margin-left:11rem;
                    
                }
            }

            
        }

        .about-cards {
                margin:3rem 2rem;
                gap:2rem;
                flex-direction:column;

                .about-card {
                    padding:2rem 1rem;
                    width:100%;
                    gap:0.5rem;
                    background-color: ${({ theme }) => theme.colors.black};

                    h3 {
                        font-size:2.5rem;
                        text-align:center;
                    }

                    p {
                        font-size:2rem;
                        width:100%;
                    }
                }
            }

            .about-features  {
                margin:1rem 1.5rem;

                h2 {
                    margin-top:2rem;
                    font-size: 3rem;
                }

                .feature-section {
                    margin-top:2rem;
                    gap:1rem;

                    .about-feature {
                    margin:1rem 2rem;
                    padding: 1rem 1rem;
                    
                    h3 {
                      
                    }

                    p {
                        width:90%;
                }

            }

                }

               

           
    }

    .about-profile-cards {
                    margin-top:5rem;
                    flex-direction:row;
                    padding:1rem 4rem;
                    gap:5rem;

                    .profile-card  {
                      

                        h2 {
                           
                        }

                        h3 {
                            
                        }
                    }
                }

}
`;

export default AboutUsSection;
