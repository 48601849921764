 const reducer = (state, action) => {
    
 //...HOME_PAGE...

    if (action.type === "HOME_UPDATE") {
        return  {
            ...state,

        //NavBar
        LogoWcs:action.payload.LogoWcs,

        //Hero-Image-Section
            heroImg: action.payload.heroImg,
            heroHeading: action.payload.heroHeading,
            heroPara: action.payload.heroPara,

        //subHero-Image-Section
            subHeroHeading: action.payload.subHeroHeading,
            subHeroPara:action.payload.subHeroPara,
            image1: action.payload.image1,
            image2: action.payload.image2,
            image3: action.payload.image3,


        //Our-Services-Home
         ourServicesHead: action.payload.ourServicesHead,
         ourServicesSub: action.payload.ourServicesSub,   
         
         ourServicesDesHead01: action.payload.ourServicesDesHead01, 
         ourServicesDesText01: action.payload.ourServicesDesText01, 
         ourServicesFeature01A: action.payload.ourServicesFeature01A, 
         ourServicesFeature01B: action.payload.ourServicesFeature01B, 
         ourServicesFeature01C: action.payload.ourServicesFeature01C, 
         ourServicesFeature01D: action.payload.ourServicesFeature01D, 

         ourServicesImage01: action.payload.ourServicesImage01, 

         ourServicesDesHead02: action.payload.ourServicesDesHead02, 
         ourServicesDesText02: action.payload.ourServicesDesText02, 
         ourServicesFeature02A: action.payload.ourServicesFeature02A, 
         ourServicesFeature02B: action.payload.ourServicesFeature02B, 
         ourServicesFeature02C: action.payload.ourServicesFeature02C, 
         ourServicesFeature02D: action.payload.ourServicesFeature02D, 

         ourServicesImage02A: action.payload.ourServicesImage02A, 
         ourServicesImage02B: action.payload.ourServicesImage02B, 


        //Result-Driven_benefits
        benefitsHead: action.payload.benefitsHead,
        benefitsSubHead: action.payload.benefitsSubHead,

        benefitsDesHead01: action.payload.benefitsDesHead01,
        benefitsDesText01: action.payload.benefitsDesText01,
        benefitsImage01: action.payload.benefitsImage01,  
        
        benefitsDesHead02: action.payload.benefitsDesHead02, 
        benefitsDesText02:  action.payload.benefitsDesText02,
        benefitsImage02:   action.payload.benefitsImage02,

        benefitsDesHead03: action.payload.benefitsDesHead03, 
        benefitsDesText03:  action.payload.benefitsDesText03,
        benefitsImage03:   action.payload.benefitsImage03,

        benefitsDesHead04: action.payload.benefitsDesHead04, 
        benefitsDesText04:  action.payload.benefitsDesText04,
        benefitsImage04:   action.payload.benefitsImage04,

        benefitsDesHead05: action.payload.benefitsDesHead05, 
        benefitsDesText05:  action.payload.benefitsDesText05,
        benefitsImage05:   action.payload.benefitsImage05,


         //Our-Tech-Stack
         techIcon01: action.payload.techIcon01,
         techIcon02: action.payload.techIcon02,
         techIcon03: action.payload.techIcon03,
         techIcon04: action.payload.techIcon04,

         techStackHead: action.payload.techStackHead,
         techStackSub: action.payload.techStackSub,

         techImg01: action.payload.techImg01,
         techName01: action.payload.techName01,

         techImg02: action.payload.techImg02,
         techName02: action.payload.techName02,

         techImg03: action.payload.techImg03,
         techName03: action.payload.techName03,
         
         techImg04: action.payload.techImg04,
         techName04: action.payload.techName04,

         //Accordion FAQ
         FaqHead:action.payload.FaqHead,
         FaqSubHead:action.payload.FaqSubHead,
         AccordionIcon:action.payload.AccordionIcon,


        }
    }

 //...SERVICES_PAGE...

  if (action.type === "SERVICES_UPDATE") {
    return {
        ...state,

        //NavBar
        LogoWcs:action.payload.LogoWcs,

        //Hero-Image-Section
          heroImg: action.payload.heroImg,
          heroHeading: action.payload.heroHeading,
          heroPara: action.payload.heroPara,

          //Result-Driven_benefits
        benefitsHead: action.payload.benefitsHead,
        benefitsSubHead: action.payload.benefitsSubHead,

        benefitsDesHead01: action.payload.benefitsDesHead01,
        benefitsDesText01: action.payload.benefitsDesText01,
        benefitsImage01: action.payload.benefitsImage01,  
        
        benefitsDesHead02: action.payload.benefitsDesHead02, 
        benefitsDesText02:  action.payload.benefitsDesText02,
        benefitsImage02:   action.payload.benefitsImage02,

        benefitsDesHead03: action.payload.benefitsDesHead03, 
        benefitsDesText03:  action.payload.benefitsDesText03,
        benefitsImage03:   action.payload.benefitsImage03,

        benefitsDesHead04: action.payload.benefitsDesHead04, 
        benefitsDesText04:  action.payload.benefitsDesText04,
        benefitsImage04:   action.payload.benefitsImage04,

        benefitsDesHead05: action.payload.benefitsDesHead05, 
        benefitsDesText05:  action.payload.benefitsDesText05,
        benefitsImage05:   action.payload.benefitsImage05,

        //Features-List
        FeatureHead: action.payload.FeatureHead,

        FeatureIcon01: action.payload.FeatureIcon01,
        FeatureSubHead01: action.payload.FeatureSubHead01,
        FeatureText01: action.payload.FeatureText01,

        FeatureIcon02: action.payload.FeatureIcon02,
        FeatureSubHead02: action.payload.FeatureSubHead02,
        FeatureText02: action.payload.FeatureText02,

        FeatureIcon03: action.payload.FeatureIcon03,
        FeatureSubHead03: action.payload.FeatureSubHead03,
        FeatureText03: action.payload.FeatureText03,

        FeatureIcon04: action.payload.FeatureIcon04,
        FeatureSubHead04: action.payload.FeatureSubHead04,
        FeatureText04: action.payload.FeatureText04,

        FeatureIcon05: action.payload.FeatureIcon05,
        FeatureSubHead05: action.payload.FeatureSubHead05,
        FeatureText05: action.payload.FeatureText05,

        FeatureIcon06: action.payload.FeatureIcon06,
        FeatureSubHead06: action.payload.FeatureSubHead06,
        FeatureText06: action.payload.FeatureText06,

        FeatureIcon07: action.payload.FeatureIcon07,
        FeatureSubHead07: action.payload.FeatureSubHead07,
        FeatureText07: action.payload.FeatureText07,

        FeatureIcon08: action.payload.FeatureIcon08,
        FeatureSubHead08: action.payload.FeatureSubHead08,
        FeatureText08: action.payload.FeatureText08,

        FeatureIcon09: action.payload.FeatureIcon09,
        FeatureSubHead09: action.payload.FeatureSubHead09,
        FeatureText09: action.payload.FeatureText09,

       //Design_Process
       DesignProcessHead: action.payload.DesignProcessHead,
       DesignProcessSub: action.payload.DesignProcessSub,

       DesignProcessIcon: action.payload.DesignProcessIcon,
       

       StepNumber01: action.payload.StepNumber01,
       StepHead01: action.payload.StepHead01,
       StepDes01: action.payload.StepDes01,
       StepIcon01: action.payload.StepIcon01,

       StepNumber02: action.payload.StepNumber02,
       StepHead02: action.payload.StepHead02,
       StepDes02: action.payload.StepDes02,
       StepIcon02: action.payload.StepIcon02,

       StepNumber03: action.payload.StepNumber03,
       StepHead03: action.payload.StepHead03,
       StepDes03: action.payload.StepDes03,
       StepIcon03: action.payload.StepIcon03,

       StepNumber04: action.payload.StepNumber04,
       StepHead04: action.payload.StepHead04,
       StepDes04: action.payload.StepDes04,
       StepIcon04: action.payload.StepIcon04,

       StepNumber05: action.payload.StepNumber05,
       StepHead05: action.payload.StepHead05,
       StepDes05: action.payload.StepDes05,
       StepIcon05: action.payload.StepIcon05,

       StepNumber06: action.payload.StepNumber06,
       StepHead06: action.payload.StepHead06,
       StepDes06: action.payload.StepDes06,
       StepIcon06: action.payload.StepIcon06,

       StepNumber07: action.payload.StepNumber07,
       StepHead07: action.payload.StepHead07,
       StepDes07: action.payload.StepDes07,
       StepIcon07: action.payload.StepIcon07,

       StepNumber08: action.payload.StepNumber08,
       StepHead08: action.payload.StepHead08,
       StepDes08: action.payload.StepDes08,
       StepIcon08: action.payload.StepIcon08,

       StepNumber09: action.payload.StepNumber09,
       StepHead09: action.payload.StepHead09,
       StepDes09: action.payload.StepDes09,
       StepIcon09: action.payload.StepIcon09,
       

        





    }
  }


 //...PORTFOLIO_PAGE...

 if (action.type === "PORTFOLIO_UPDATE") {
    return {
        ...state,

        //NavBar
        LogoWcs:action.payload.LogoWcs,

        //Hero-Image-Section
          heroImg: action.payload.heroImg,
          heroHeading: action.payload.heroHeading,
          heroPara: action.payload.heroPara,

        //OUR-WORK
        BusinessCategoryScreen01: action.payload.BusinessCategoryScreen01,
        BusinessCategory01: action.payload.BusinessCategory01,

        BusinessCategoryScreen02: action.payload.BusinessCategoryScreen02,
        BusinessCategory02: action.payload.BusinessCategory02,

        BusinessCategoryScreen03: action.payload.BusinessCategoryScreen03,
        BusinessCategory03: action.payload.BusinessCategory03,

        BusinessCategoryScreen04: action.payload.BusinessCategoryScreen04,
        BusinessCategory04: action.payload.BusinessCategory04,

        BusinessCategoryScreen05: action.payload.BusinessCategoryScreen05,
        BusinessCategory05: action.payload.BusinessCategory05,

        BusinessCategoryScreen06: action.payload.BusinessCategoryScreen06,
        BusinessCategory06: action.payload.BusinessCategory06,

        BusinessCategoryScreen07: action.payload.BusinessCategoryScreen07,
        BusinessCategory07: action.payload.BusinessCategory07,

        BusinessCategoryScreen08: action.payload.BusinessCategoryScreen08,
        BusinessCategory08: action.payload.BusinessCategory08,

        BusinessCategoryScreen09: action.payload.BusinessCategoryScreen09,
        BusinessCategory09: action.payload.BusinessCategory09,

        BusinessCategoryScreen10: action.payload.BusinessCategoryScreen10,
        BusinessCategory10: action.payload.BusinessCategory10,

        BusinessCategoryScreen11: action.payload.BusinessCategoryScreen11,
        BusinessCategory11: action.payload.BusinessCategory11,

        BusinessCategoryScreen12: action.payload.BusinessCategoryScreen12,
        BusinessCategory12: action.payload.BusinessCategory12,

        BusinessCategoryScreen13: action.payload.BusinessCategoryScreen13,
        BusinessCategory13: action.payload.BusinessCategory13,

        BusinessCategoryScreen14: action.payload.BusinessCategoryScreen14,
        BusinessCategory14: action.payload.BusinessCategory14,

        BusinessCategoryScreen15: action.payload.BusinessCategoryScreen15,
        BusinessCategory15: action.payload.BusinessCategory15,

        BusinessCategoryScreen16: action.payload.BusinessCategoryScreen16,
        BusinessCategory16: action.payload.BusinessCategory16,

        BusinessCategoryScreen17: action.payload.BusinessCategoryScreen17,
        BusinessCategory17: action.payload.BusinessCategory17,

        BusinessCategoryScreen18: action.payload.BusinessCategoryScreen18,
        BusinessCategory18: action.payload.BusinessCategory18,

        BusinessCategoryScreen19: action.payload.BusinessCategoryScreen19,
        BusinessCategory19: action.payload.BusinessCategory19,

        BusinessCategoryScreen20: action.payload.BusinessCategoryScreen20,
        BusinessCategory20: action.payload.BusinessCategory20,

         //Clothing & Appareal
        EcomCategory01: action.payload.EcomCategory01,
        ecomCategoryScreen01a: action.payload.ecomCategoryScreen01a,
        ecomCategory01a: action.payload.ecomCategory01a,
        ecomCategoryScreen01b: action.payload.ecomCategoryScreen01b,
        ecomCategory01b: action.payload.ecomCategory01b,
        ecomCategoryScreen01c: action.payload.ecomCategoryScreen01c,
        ecomCategory01c: action.payload.ecomCategory01c,

        EcomCategory02: action.payload.EcomCategory02,
        ecomCategoryScreen02a: action.payload.ecomCategoryScreen02a,
        ecomCategory02a: action.payload.ecomCategory02a,
        ecomCategoryScreen02b: action.payload.ecomCategoryScreen02b,
        ecomCategory02b: action.payload.ecomCategory02b,
        ecomCategoryScreen02c: action.payload.ecomCategoryScreen02c,
        ecomCategory02c: action.payload.ecomCategory02c,
        ecomCategoryScreen02d: action.payload.ecomCategoryScreen02d,
        ecomCategory02d: action.payload.ecomCategory02d,
        ecomCategoryScreen02e: action.payload.ecomCategoryScreen02e,
        ecomCategory02e: action.payload.ecomCategory02e,
        ecomCategoryScreen02f: action.payload.ecomCategoryScreen02f,
        ecomCategory02f: action.payload.ecomCategory02f,

        EcomCategory03: action.payload.EcomCategory03,
        ecomCategoryScreen03a: action.payload.ecomCategoryScreen03a,
        ecomCategory03a: action.payload.ecomCategory03a,
        ecomCategoryScreen03b: action.payload.ecomCategoryScreen03b,
        ecomCategory03b: action.payload.ecomCategory03b,
        ecomCategoryScreen03c: action.payload.ecomCategoryScreen03c,
        ecomCategory03c: action.payload.ecomCategory03c,
        ecomCategoryScreen03d: action.payload.ecomCategoryScreen03d,
        ecomCategory03d: action.payload.ecomCategory03d,
        ecomCategoryScreen03e: action.payload.ecomCategoryScreen03e,
        ecomCategory03e: action.payload.ecomCategory03e,
        ecomCategoryScreen03f: action.payload.ecomCategoryScreen03f,
        ecomCategory03f: action.payload.ecomCategory03f,
        ecomCategoryScreen03g: action.payload.ecomCategoryScreen03g,
        ecomCategory03g: action.payload.ecomCategory03g,

        EcomCategory04: action.payload.EcomCategory04,
        ecomCategoryScreen04a: action.payload.ecomCategoryScreen04a,
        ecomCategory04a: action.payload.ecomCategory04a,
        ecomCategoryScreen04b: action.payload.ecomCategoryScreen04b,
        ecomCategory04b: action.payload.ecomCategory04b,
        ecomCategoryScreen04c: action.payload.ecomCategoryScreen04c,
        ecomCategory04c: action.payload.ecomCategory04c,
        
        EcomCategory05: action.payload.EcomCategory05,
        ecomCategoryScreen05a: action.payload.ecomCategoryScreen05a,
        ecomCategory05a: action.payload.ecomCategory05a,
        ecomCategoryScreen05b: action.payload.ecomCategoryScreen05b,
        ecomCategory05b: action.payload.ecomCategory05b,
        ecomCategoryScreen05c: action.payload.ecomCategoryScreen05c,
        ecomCategory05c: action.payload.ecomCategory05c,
        ecomCategoryScreen05d: action.payload.ecomCategoryScreen05d,
        ecomCategory05d: action.payload.ecomCategory05d,

        EcomCategory06: action.payload.EcomCategory06,
        ecomCategoryScreen06a: action.payload.ecomCategoryScreen06a,
        ecomCategory06a: action.payload.ecomCategory06a,
        ecomCategoryScreen06b: action.payload.ecomCategoryScreen06b,
        ecomCategory06b: action.payload.ecomCategory06b,

        EcomCategory07: action.payload.EcomCategory07,
        ecomCategoryScreen07a: action.payload.ecomCategoryScreen07a,
        ecomCategory07a: action.payload.ecomCategory07a,
        ecomCategoryScreen07b: action.payload.ecomCategoryScreen07b,
        ecomCategory07b: action.payload.ecomCategory07b,
        ecomCategoryScreen07c: action.payload.ecomCategoryScreen07c,
        ecomCategory07c: action.payload.ecomCategory07c,
        ecomCategoryScreen07d: action.payload.ecomCategoryScreen07d,
        ecomCategory07d: action.payload.ecomCategory07d,

        EcomCategory08: action.payload.EcomCategory08,
        ecomCategoryScreen08a: action.payload.ecomCategoryScreen08a,
        ecomCategory08a: action.payload.ecomCategory08a,
        ecomCategoryScreen08b: action.payload.ecomCategoryScreen08b,
        ecomCategory08b: action.payload.ecomCategory08b,
        


    }
  }

 //...BLOG_PAGE...

 if (action.type === "BLOG_UPDATE") {
    return {
        ...state,

        //NavBar
        LogoWcs:action.payload.LogoWcs,

        //Hero-Image-Section
          heroImg: action.payload.heroImg,
          heroHeading: action.payload.heroHeading,
          heroPara: action.payload.heroPara,
    }
  }



//..ABOUT_PAGE...

    if (action.type === "ABOUT_UPDATE") {
        return  {
        ...state,

        //NavBar
        LogoWcs:action.payload.LogoWcs,

        //Hero-Image-Section
          heroImg: action.payload.heroImg,
          heroHeading: action.payload.heroHeading,
          heroPara: action.payload.heroPara,

        //ABOUT-US-SECTION
        AboutHead: action.payload.AboutHead,
        AboutPara: action.payload.AboutPara,
        AboutHeroImg: action.payload.AboutHeroImg,

        AboutCardHead01: action.payload.AboutCardHead01,
        AboutCardPara01: action.payload.AboutCardPara01,
        AboutCardHead02: action.payload.AboutCardHead02,
        AboutCardPara02: action.payload.AboutCardPara02,

        AboutFeatureHead: action.payload.AboutFeatureHead,
        FeatureHead01: action.payload.FeatureHead01,
        AboutFeaturePara01: action.payload.AboutFeaturePara01,
        FeatureHead02: action.payload.FeatureHead02,
        AboutFeaturePara02: action.payload.AboutFeaturePara02,
        FeatureHead03: action.payload.FeatureHead03,
        AboutFeaturePara03: action.payload.AboutFeaturePara03,
        FeatureHead04: action.payload.FeatureHead04,
        AboutFeaturePara04: action.payload.AboutFeaturePara04,
        FeatureHead05: action.payload.FeatureHead05,
        AboutFeaturePara05: action.payload.AboutFeaturePara05,
        FeatureHead06: action.payload.FeatureHead06,
        AboutFeaturePara06: action.payload.AboutFeaturePara06,

        ProfileHead01: action.payload.ProfileHead01,
        ProfileImg01: action.payload.ProfileImg01,
        ProfileName01: action.payload.ProfileName01,
        ProfileHead02: action.payload.ProfileHead02,
        ProfileImg02: action.payload.ProfileImg02,
        ProfileName02: action.payload.ProfileName02,






        }
    }


//..CONTACT_PAGE...

if (action.type === "CONTACT_UPDATE") {
    return  {
    ...state,

    //NavBar
    LogoWcs:action.payload.LogoWcs,

    //Hero-Image-Section
    ContactHeroImg: action.payload.ContactHeroImg,

    //Contact Form
    ContactFormHead: action.payload.ContactFormHead,
    ContactFormIcon: action.payload.ContactFormIcon,
     

    
    }
}


 return state;
};


export default reducer;