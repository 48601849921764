import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { Button } from "../Styles/Button";
import {  useGlobalContext } from "../context";

const HeroImageSection = () => {
    const {heroImg, heroHeading, heroPara} = useGlobalContext();

    return(
        <Wrapper>
            <div className="hero">
                <img alt="hero-img" src={heroImg} />

                <div className="hero-text">
                    <h1>{heroHeading}</h1>
                    <h3>{heroPara}</h3>
                    <Button className="btn contactus-btn">
                        <NavLink to="../contactus">Contact Us</NavLink>
                    </Button>
                </div>
            </div>
        </Wrapper>
    )

}


const Wrapper = styled.section`
 .hero {
    margin:0;
    width:100%;
    height: 70vh;

    img {
        width:100%;
        height:100%;
        object-fit:cover;
        filter: brightness(70%)
        
    }

    .hero-text {
        position:absolute;
        text-align: left;
        top:25%;
       left:4.5rem;
      
       h1 {
        margin-top:2rem;
       }

       h3 {
        font-size:2.5rem;
        font-weight:500;
        margin:2rem 0;
       }

      
    }
 }


 //998px
@media (max-width:${({ theme }) => theme.media.tab})  {
    .hero  {
        .hero-text {

            
        }
}

 //768px
@media (max-width:${({ theme }) => theme.media.mobile})  {

    .hero {
        img {
        width:100%;
        height: 60vh;
    }
    .hero-text {
        left:4rem;
        margin-top:-4rem;
        h1 {
            font-size:5rem;
            width:100%;
        }

        h3 {
            margin-top:1rem;
            font-size: 3rem;
            width:100%;
            
        }
    }
    }

  
}
`;


export default HeroImageSection;