import React, { useEffect } from "react";


import Home from "./Routes/Home";
import Services from "./Routes/Services";
import Portfolio from "./Routes/Portfolio";
import AboutUs from "./Routes/AboutUs";
import ContactUs from "./Routes/ContactUs";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Blog from "./Routes/Blog";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./GlobalStyle";
import ScrollToTop from "./Components/ScrollToTop";

export default function App()  {


  
 const theme= {
  colors: {
    heading: "rgba(24 24 29)",
    text:"rgba(24 24 29)",
    white:"#fff",
    black:"#212529",
    darkBlack: "#000000",
    blue: "#243061",
    grey:"#808080",
    helper:"#8490ff",
    bg:"rgb(240 245 256)",
    footer_bg:"#0a1435",
    btn:"rgb(98 84 243)",
    border:"rgba(98,84,243,0.5)",
    hr:"#ffffff",
    green: "#008000",
    gradient:"linear-gradient(0deg, rgba(132 144 255) 0%, rgba(98 189 252) 100%)",
    shadow:"rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
    shadowSupport: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
  },
  
  media:{mobile: "768px", tab: "998px"},

 }



  return(
    
<div className="App">

<ThemeProvider theme={theme}>
 <GlobalStyle/>

  <Router>

  <ScrollToTop/>

  <Header/>

   <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/portfolio" element={<Portfolio/>} />
        <Route path="/aboutus" element={<AboutUs/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/contactus" element={<ContactUs/>} />
    </Routes>

   <Footer/>

  </Router>

</ThemeProvider>

</div>
  
   
  )
}

