import React from "react";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { useGlobalContext } from "../context";

const Footer = () => {

  const {LogoWcs} = useGlobalContext();
  return(

    <Wrapper>

 <div className="footer">

    <div className="footer-top">

       <div className="company-name" >
         <img alt="imglogo" src={LogoWcs} />
         <h1>Web Consultancy Services</h1>
          <p>Your Path to Digital Excellence Starts here. </p>
       </div>

        <div className="social-links">

         <a href="https://www.instagram.com/webconsultancyservices" target="_blank">
           <i className="fa-brands fa-instagram-square" ></i>
         </a>

         <a href="https://www.youtube.com/@webconsultancyservices6233/featured" target="_blank">
           <i className="fa-brands fa-youtube-square" ></i>
         </a>

         <a href="https://www.facebook.com/profile.php?id=61551408862869" target="_blank">
           <i className="fa-brands fa-facebook-square" ></i>
         </a>

         

        </div>

    </div>

    <div className="bottom-footer">

        <div className="footer-data">
          <h4>Quik Links</h4>
          <a href="/">Home</a>
          <a href="/services">Services</a>
          <a href="/portfolio">Portfolio</a>
          <a href="/blog">Blog</a>
          <a href="/aboutus">About</a>
        </div>


        <div className="footer-data">
          <h4>Hours of Operation</h4>
          <p>Monday to Friday</p>
          <p>10:00AM - 7:00PM</p>
          <p>Saturday to Sunday</p>
          <p>10:00AM - 7:00PM</p>
        </div>

        <div className="footer-data">
          <h4>Contact Us</h4>
          <p href="/">+91-8975663020</p>
          <a href="https://mail.google.com/mail/u/0/#inbox?compose=new">enquiry@webconsultancyservices.in</a>
          <a href="/">Nagpur, India.</a>
        </div>

    </div>

    <div className="copy-rights"> 
       <hr/>
       <div>
       <p>
       @{new Date().getFullYear()} Web Consultancy Services. All Rights Reserved.
       </p>
      </div>
         

    </div>
 </div>

 </Wrapper>

  )
}

const Wrapper = styled.section`

.footer {
  padding: 6rem 6rem;
  background-color:  ${({theme}) => theme.colors.darkBlack};
  z-index:99;
  width:100%;

  .footer-top {
    display:flex;
    align-items:center;
    text-align: start;
    justify-content: space-between;
    flex-direction:row;
    width:100%;
    margin: 2rem 1rem;
  
    .company-name img {
    height: auto;
  max-width: 6.5rem;
 
  }

  .company-name h1 {
    font-size:3rem;
    width:70%;
    color:  ${({ theme }) => theme.colors.white};
  }

  .company-name p {
    font-size:2rem;
    color:  ${({ theme }) => theme.colors.white};
    font-weight:500;
  }

 .social-links i {
  font-size:3rem;
    color:  ${({ theme }) => theme.colors.white};
    font-weight:500;
    margin-right:1rem;
 }

 .social-links i:hover {
  ${'' /* color:  ${({ theme }) => theme.colors.black}; */}
  font-size:3.5rem;
 }
  

}




.bottom-footer {
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
  padding: 2rem 2rem;
  text-align: start;
  width:100%;

  .footer-data  {
    display:flex;
  flex-direction:column;

    h4 {
  font-size:2rem;
  color:  ${({ theme }) => theme.colors.white};
  margin:1rem 0rem; 
  width:90%;
    }

    a {
  font-size:2rem;
  color:  ${({ theme }) => theme.colors.white};
  margin:0.5rem 0rem; 
  width:90%;
  
}

  a:hover {
    
}

p {
  color:  ${({ theme }) => theme.colors.white};
}

}

}

.copy-rights p {
      font-size:1.5rem;
      text-align:center;
      color:  ${({ theme }) => theme.colors.white}
    }

}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
  .footer {
    padding: 6rem 2rem;
    width:100%;

    .top-footer {
      flex-direction:column;
      gap:3rem;
      align-items:flex-start;
      width:90%;

      .company-name h1 {
         
          width:60%;
          margin-top:2rem;
        
        
      }

      .company-name p {
          width:50%;
        }
    }

    .bottom-footer {
      flex-direction:column;
      gap:2rem;

      .footer-data {
        a {
          font-size:1.5rem;
        }
      }
      
    }

    .copy-rights p {
      font-size:1.2rem;
      text-align:center;
      color:  ${({ theme }) => theme.colors.white}
    }



  }
}

`;




export default Footer;