import React from "react";
import { useGlobalContext } from "../context";
import { styled } from "styled-components";
import { Button } from "../Styles/Button";
import { NavLink } from "react-router-dom";


const OurServices = () => {
    const {
        ourServicesHead,
        ourServicesSub, 

        ourServicesDesHead01, 
        ourServicesDesText01, 

        ourServicesFeature01A,
        ourServicesFeature01B,
        ourServicesFeature01C,
        ourServicesFeature01D,

        ourServicesImage01,

        ourServicesDesHead02,
        ourServicesDesText02,

        ourServicesFeature02A,
        ourServicesFeature02B,
        ourServicesFeature02C,
        ourServicesFeature02D,

        ourServicesImage02A,
        ourServicesImage02B,



    } = useGlobalContext();

    return(
        <Wrapper>
            <div className="ourServices">
                <div className="ourServices-head">
                    <p>{ourServicesHead}</p>
                    <h1>{ourServicesSub}</h1>
                </div>

                <div className="ourServices-bottom">

                    <div className="ourServices-type">
                  
                        <div className="ourServices-des-text" >

                            <h2>{ourServicesDesHead01}</h2>
                            <p>{ourServicesDesText01}</p>

                            <div className="ourServices-feature">
                               <p>{ourServicesFeature01A}</p>
                               <p>{ourServicesFeature01B}</p>
                               <p>{ourServicesFeature01C}</p>
                               <p>{ourServicesFeature01D}</p>

                               <Button className="btn contactus-btn">
                        <NavLink to="./portfolio">Portfolio</NavLink>
                    </Button>
                            </div>

                        </div>

                        <div className="ourServices-img">

                           <img alt="img" src={ourServicesImage01} width="570px" height= "456px"></img>

                        </div>

                    </div>

                    <div className="ourServices-type-reverse">
                  
                       <div className="ourServices-des-text" >
                          <h2>{ourServicesDesHead02}</h2>
                          <p>{ourServicesDesText02}</p>

                            <div className="ourServices-feature">
                              <p>{ourServicesFeature02A}</p>
                              <p>{ourServicesFeature02B}</p>
                              <p>{ourServicesFeature02C}</p>
                              <p>{ourServicesFeature02D}</p>

                              <Button className="btn contactus-btn">
                        <NavLink to="./portfolio">Portfolio</NavLink>
                    </Button>

                              
                            </div>

                        </div>

                        <div className="ourServices-imgs">

                           <img alt="image" src={ourServicesImage02A}  className="boyImg" ></img>

                           <img alt="img2" src={ourServicesImage02B} className="girlImg"></img>

                        </div>

                    </div>

                </div>
                
            </div>

        </Wrapper>
    )
}

const Wrapper = styled.section`

.ourServices {
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    background-color:  ${({ theme }) => theme.colors.darkBlack};
    padding: 3rem 0rem;
  
    .ourServices-head {
        display:flex;
        flex-direction: column;
        align-items: center;
        margin-top: 5rem;
        

        p {
            font-size: 2rem;
            font-weight: 500;
        }

        h1 {
            font-size: 4rem;
            margin-top: 3rem;
            text-align:center;
        }
    }

    .ourServices-bottom {
      display:flex;
      flex-direction: column;
      align-items: center;
      
        .ourServices-type {
          display:flex;
          flex-direction: row;
          justify-content:space-between;
          align-items:center;
          margin: 1rem 1rem;
          margin-left:10rem;
      
            .ourServices-des-text {
                display:flex;
                flex-direction: column;
                align-items:flex-start;
                width:50%;

              h2 {
                font-size: 4rem;
                text-align: left;
                margin-top: 3rem;
                font-weight:550;
                }

             p {
               width: 80%;
               font-size: 2.4rem;
               font-weight: 500;
            }

            .ourServices-feature {
               display:grid;
               grid-template-columns: 1fr 1fr;
               grid-gap:1rem;
               width:100%;
               margin:2rem 0rem;
               
                p {
                    width:97%;
                    font-size:2rem;
                   padding:1rem 2rem;
                   border:none;
                   border-radius:1rem;
                   background-color:  ${({ theme }) => theme.colors.bg};
                   color:  ${({ theme }) => theme.colors.black};
                } 

                p:hover {
                    padding:1rem 2rem;
                    border:none;
                    background-color:  ${({ theme }) => theme.colors.blue};
                    color:  ${({ theme }) => theme.colors.white};
                }


                Button {
                  margin:2rem 0rem;
                  padding:1rem 1rem;
                  width:70%;
                  border-radius: 1rem;
                  font-size:2rem;
                
                }
            }  
            }

         .ourServices-img {
             margin:3rem 1rem;
             height:auto;
             align-items:center;
             width:40%;
             height:auto;
            }

        }

        .ourServices-type-reverse {
          display:flex;
          flex-direction: row-reverse;
          justify-content:space-between;
          align-items:center;
          margin: 1rem 1rem;
          padding: 1rem 1rem;
          width:90%;
      
            .ourServices-des-text {
                display:flex;
                flex-direction: column;
                align-items:flex-start;
                margin-left:6rem;
                width:50%;

              h2 {
                font-size: 4rem;
                text-align: left;
                margin-top: 3rem;
                font-weight:550;
                }

             p {
               width: 85%;
               font-size: 2.4rem;
               font-weight: 500;
            }

            .ourServices-feature {
               display:grid;
               grid-template-columns: 1fr 1fr;
               grid-gap:1rem;
               width:100%;
               margin:2rem 0rem;

                p {
                    width:97%;
                    font-size:2rem;
                   padding:1rem 2rem;
                   border:none;
                   border-radius:1rem;
                   background-color:  ${({ theme }) => theme.colors.bg};
                   color:  ${({ theme }) => theme.colors.black};
                } 

                p:hover {
                    padding:1rem 2rem;
                    border:none;
                    background-color:  ${({ theme }) => theme.colors.blue};
                    color:  ${({ theme }) => theme.colors.white};
                }

                Button {
                  margin:2rem 0rem;
                  padding:1rem 1rem;
                  width:70%;
                  border-radius: 1rem;
                  font-size:2rem;
                }
            }  
            }

         .ourServices-imgs {
            display:flex;
            align-items:center;
            width:50%;
             margin:3rem 1rem;
             height:auto;
            padding:1rem 5rem;

            .boyImg {
                margin-left:-3rem;
            }

            .girlImg {
                margin-left:-14rem;
            }
            }
            }
        }
    }

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .ourServices {
        margin-top:-1rem;
        padding: 1rem 2rem;

        .ourServices-head {
            margin-top: 3rem;
        }

        .ourServices-bottom  {

            .ourServices-type {
                margin: 1rem 4rem;

                .ourServices-des-text  {
                    margin-left:2rem;

                    h2 {
                        font-size: 3rem;
                    }
                    p {
                        width: 95%;
                      font-size: 2.4rem;
                    }
                    .ourServices-feature  {

                    p {
                        padding:1rem 1rem;
                    }
                }
                }
            }

            .ourServices-type-reverse {
                margin: 1rem 4rem;

                .ourServices-des-text  {
                    margin-left:5rem;
                    width:60%;

                    h2 {
                        font-size: 3rem;
                    }
                    p {
                        width: 100%;
                      font-size: 2.4rem;
                    }

                    .ourServices-feature {
                        p {
                            padding:1rem 1rem; 
                        }
                    }

                }

                .ourServices-imgs {
                    .boyImg {
                margin-left:-5rem;
            }

            .girlImg {
                margin-left:-16rem;
            }
                }
            }
        }
    }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile})  {

 .ourServices {
    margin-top:-1rem;
    width:100%;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    

    .ourServices-head {
        p{
            font-weight: 520;
        }

        h1 {
            width: 85%;
            margin-top: 1rem;
        }
    }

    .ourServices-bottom {
        margin: 1rem 0rem;

        .ourServices-type {
        flex-direction: column-reverse;
        margin: 1rem 0rem;
        width: 100%;

        .ourServices-des-text {
            margin-left:1rem;
            width:100%;
            h2 {

            }

            p {
                width: 95%;
            }

            .ourServices-feature {
            display:flex;
            flex-direction:column;
            width:95%;
            p {
                padding:1rem 1rem;
            }

            Button {
                width:40%;
                padding:1rem 1rem;
                font-size:2rem;
            }
        }
        }

        .ourServices-img {
            margin-left:10rem;
            width:70%;
           img {
             width:35rem;
           }
        }
    }

    .ourServices-type-reverse  {
        flex-direction: column-reverse;
        margin-left:1rem;
        margin-bottom:9rem;
        width: 100%;

        .ourServices-des-text {
            margin-left:1rem;
            width:100%;
            h2 {

            }

            p {

            }

            .ourServices-feature {
            display:flex;
            flex-direction:column;
            width:95%;

            p{

            }

            Button {
                width:50%;
                margin-left:1rem
            }
            
        }
        }
        .ourServices-imgs {
            width:70%;
            margin-left:7rem;
            
        img {
             width:30rem;
             align-self:center;
            
           }
        } 
    }
    }
 }

}

`;

export default OurServices;