import React, { useEffect } from "react";
import { useGlobalContext } from "../context";
import HeroImageSection from "../Components/HeroImageSection";
import ReachOut from "../Components/ReachOut";
import Benefits from "../Components/Benefits";
import Features from "../Components/Features";
import DesignProcess from "../Components/DesignProcess";
import Navbar from "../Components/Navbar";
import Header from "../Components/Header";


const Services = () => {

  const {updateServicesPage} = useGlobalContext();

  useEffect(() => updateServicesPage(), []);

  return(
    <>
    {/* <Header/> */}
    {/* <Navbar/> */}
      <HeroImageSection/>
      <Benefits/>
      <Features/>
      <DesignProcess/>
      <ReachOut/>
      
    </>
  )
}

export default Services;