import React from "react";
import { useGlobalContext } from "../context";
import { styled } from "styled-components";

const OurTechStack = () => {

    const {

      techIcon01,
      techIcon02,
      techIcon03,
      techIcon04,
      techStackHead,
      techStackSub,
      techImg01,
      
        
    } = useGlobalContext();

    return(
        <Wrapper>

          <div className="techStack">

            <div className="tech-head">

              <p>{techStackHead}</p> 
              <h1>{techStackSub}</h1>

            </div>

            <div className="tech-bottom">

              <div className="tech-icon">

                <img alt="img" className="tech-Icon" src={techIcon01}  ></img>
                
              </div>

              <div className="tech-names">
 
                <img alt="techImg" src={techImg01}  ></img>
                    
              </div>

            </div>

          </div>

        </Wrapper>
    )
} 

const Wrapper = styled.section`

.techStack {
  display:flex;
  justify-content:space-between;
  align-items:center;
  flex-direction:column;
  gap:4rem;
  padding:2rem 5rem;
  background-color: ${({ theme }) => theme.colors.darkBlack};
  margin-top:-1rem;

  .tech-head {
    text-align:center;

    p {
      font-size: 2rem;
       font-weight: 500;
    }

    h1 {
      font-size: 4rem;
      margin-top: 2rem;
    }
  }

  .tech-bottom {
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    margin-left:10rem;
    gap:10rem;
   
    .tech-icon {

      img {
        height:auto;
      }
    }

      .tech-names {
       
        img {
        width:60%;
        height:auto;
        margin-left:10rem;
      }
        
      }
  }
}


//998px
@media (max-width:${({ theme }) => theme.media.tab}) {

}


//768px
@media (max-width:${({ theme }) => theme.media.mobile})  {

.techStack {
  display:flex;
  gap:2rem;
  padding:0;
  
  .tech-head {
   margin-top:4rem;
  
    p {
      font-size: 2rem;
       font-weight: 550;
    }

    h1 {
      font-size: 2.7rem;
      margin-top: 2rem;
    }
  }

  .tech-bottom {
    display:flex;
    flex-direction:column;
    
    .tech-icon  {

      img {
        width:70%;
        height:auto;
      }
    }
    .tech-names {
       
       img {
       width:70%;
       height:auto;
       margin-left:1rem;
       margin-top:-7rem;
       margin-bottom:5rem;
     }
       
     }
  }
}
}
`;

export default OurTechStack;
