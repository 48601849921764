import React, { useState } from 'react';
import { styled } from "styled-components";
import { useGlobalContext } from '../context';

function AccordionFAQ({ items }) {
  const [activeIndex, setActiveIndex] = useState(null);


  const handleClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

const {FaqHead,FaqSubHead,AccordionIcon} = useGlobalContext();


  return (
  <Wrapper>
    <div className='Accordion'>

      <div className='accordion-top'>
        <p>{FaqHead}</p>
        <h1>{FaqSubHead}</h1>
      </div>

      <div className='accordion-bottom'>

        <div className="accordion-faq">

          {items.map((item, index) => (

          <div key={index} className="accordion-item">
           <div
              className={`accordion-title ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleClick(index)}
             >

             {item.question}

             <span className="accordion-icon">
              {activeIndex === index ? '➖' : '➕'}
             </span>

            </div>

            {activeIndex === index && (

              <div className="accordion-content">
               {item.answer}
              </div>

            )
            }
          </div>

         ))}
        </div>

        <div className='accordion-icon'>
          <img alt='accordion-icon' src={AccordionIcon}></img>
        </div>
        

      </div>

    </div>
</Wrapper>
  );
}

const Wrapper = styled.section`

.Accordion {
  display:flex;
  flex-direction:column;
  align-items:center;
  background-color: ${({ theme }) => theme.colors.darkBlack};
  margin-top:-1rem;

  .accordion-top {
    display:flex;
    flex-direction:column;
    align-items:center;
    gap:1rem;
    margin-top:2rem;
    p {
      font-size:2rem;
      color: ${({ theme }) => theme.colors.white};
    }
    h1 {
      font-size:4rem;
      color: ${({ theme }) => theme.colors.white};
    }
  }

  .accordion-bottom {
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    width:90%;
    ${'' /* margin-right:20rem; */}
    margin-top:4rem;
    padding: 1rem 10rem;
    
    .accordion-faq {
      width: 50%;
      max-width: auto;
      ${'' /* margin: 0 auto; */}
      
      .accordion-item {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 1rem;
        overflow: hidden;
          .accordion-title {
            padding: 10px;
            background-color: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.white};
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight:550;
            font-size:2rem;
          }
          .accordion-title.active {
           background-color: ${({ theme }) => theme.colors.darkBlack};
 
          }
         .accordion-content {
            padding: 10px;
            background-color: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.white};
            font-size:1.5rem;
          }
       }
    }
      .accordion-icon {
         img {
        width:30rem;
        margin-right:2rem;
     }
   }
  }
}

//998px
@media (max-width:${({ theme }) => theme.media.tab})  {
 
  .Accordion  {

    .accordion-top {
      p {

      }
      h {

      }
    }

    .accordion-bottom  {

      .accordion-faq {

        .accordion-item {

        

         .accordion-title {
          font-size:1.5rem;
          
        }

        .accordion-title.active {

        }
         
        .accordion-content  {
  
        }
        }
      }

      .accordion-icon  {

        img {
          width:25rem;
        }


      }
    }
  }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {

  .Accordion  {

.accordion-top {
  p {
    font-size:2rem;
  }
  h1 {
    font-size:4rem;
  }
}

.accordion-bottom  {
  flex-direction:column-reverse;
  padding: 1rem 2rem;

  .accordion-faq {
    width: 100%;

    .accordion-item {

    

     .accordion-title {
      font-size:1.5rem;
      
    }

    .accordion-title.active {

    }
     
    .accordion-content  {

    }
    }
  }

  .accordion-icon  {
    

    img {
      margin-top:-5rem;
      width:25rem;
    }


  }
}
}
}






`;

export default AccordionFAQ;
