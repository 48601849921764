import React from "react";
import { useGlobalContext } from "../context";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const OurWork = () => {
    const {
        BusinessCategoryScreen01,
        BusinessCategory01,

        BusinessCategoryScreen02,
        BusinessCategory02,

        BusinessCategoryScreen03,
        BusinessCategory03,

        BusinessCategoryScreen04,
        BusinessCategory04,

        BusinessCategoryScreen05,
        BusinessCategory05,

        BusinessCategoryScreen06,
        BusinessCategory06,

        BusinessCategoryScreen07,
        BusinessCategory07,

        BusinessCategoryScreen08,
        BusinessCategory08,

        BusinessCategoryScreen09,
        BusinessCategory09,

        BusinessCategoryScreen10,
        BusinessCategory10,

        BusinessCategoryScreen11,
        BusinessCategory11,

        BusinessCategoryScreen12,
        BusinessCategory12,

        BusinessCategoryScreen13,
        BusinessCategory13,

        BusinessCategoryScreen14,
        BusinessCategory14,

        BusinessCategoryScreen15,
        BusinessCategory15,

        BusinessCategoryScreen16,
        BusinessCategory16,

        BusinessCategoryScreen17,
        BusinessCategory17,

        BusinessCategoryScreen18,
        BusinessCategory18,

        BusinessCategoryScreen19,
        BusinessCategory19,
        
        BusinessCategoryScreen20,
        BusinessCategory20,
        
        
        EcomCategory01,
        ecomCategoryScreen01a,
        ecomCategory01a,
        ecomCategoryScreen01b,
        ecomCategory01b,
        ecomCategoryScreen01c,
        ecomCategory01c,

        EcomCategory02,
        ecomCategoryScreen02a,
        ecomCategory02a,
        ecomCategoryScreen02b,
        ecomCategory02b,
        ecomCategoryScreen02c,
        ecomCategory02c,
        ecomCategoryScreen02d,
        ecomCategory02d,
        ecomCategoryScreen02e,
        ecomCategory02e,
        ecomCategoryScreen02f,
        ecomCategory02f,

        EcomCategory03,
        ecomCategoryScreen03a,
        ecomCategory03a,
        ecomCategoryScreen03b,
        ecomCategory03b,
        ecomCategoryScreen03c,
        ecomCategory03c,
        ecomCategoryScreen03d,
        ecomCategory03d,
        ecomCategoryScreen03e,
        ecomCategory03e,
        ecomCategoryScreen03f,
        ecomCategory03f,
        ecomCategoryScreen03g,
        ecomCategory03g,

        EcomCategory04,
        ecomCategoryScreen04a,
        ecomCategory04a,
        ecomCategoryScreen04b,
        ecomCategory04b,
        ecomCategoryScreen04c,
        ecomCategory04c,

        EcomCategory05,
        ecomCategoryScreen05a,
        ecomCategory05a,
        ecomCategoryScreen05b,
        ecomCategory05b,
        ecomCategoryScreen05c,
        ecomCategory05c,
        ecomCategoryScreen05d,
        ecomCategory05d,

        EcomCategory06,
        ecomCategoryScreen06a,
        ecomCategory06a,
        ecomCategoryScreen06b,
        ecomCategory06b,

        EcomCategory07,
        ecomCategoryScreen07a,
        ecomCategory07a,
        ecomCategoryScreen07b,
        ecomCategory07b,
        ecomCategoryScreen07c,
        ecomCategory07c,
        ecomCategoryScreen07d,
        ecomCategory07d,

        EcomCategory08,
        ecomCategoryScreen08a,
        ecomCategory08a,
        ecomCategoryScreen08b,
        ecomCategory08b,

    } = useGlobalContext();

    return(
        <Wrapper>
            <div className="our-work">
               <p>OUR WORK</p>

                <div className="category-section">

                  <h1>Consutancy Business Web App</h1>

                    <div className="category-screens snaps-inline">
                 
                       <div className="category-screen">
                          <NavLink to="../contactus">
                           <img alt="catImg" src={BusinessCategoryScreen01}></img>
                           <h3>{BusinessCategory01}</h3>
                          </NavLink>
                        </div>

                       <div className="category-screen">
                          <NavLink to="../contactus">
                            <img alt="catImg" src={BusinessCategoryScreen02}></img>
                            <h3>{BusinessCategory02}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                           <img alt="catImg" src={BusinessCategoryScreen03}></img>
                           <h3>{BusinessCategory03}</h3>
                           </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen04}></img>
                          <h3>{BusinessCategory04}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen05}></img>
                          <h3>{BusinessCategory05}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen06}></img>
                          <h3>{BusinessCategory06}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen07}></img>
                          <h3>{BusinessCategory07}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen08}></img>
                          <h3>{BusinessCategory08}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen09}></img>
                          <h3>{BusinessCategory09}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen10}></img>
                          <h3>{BusinessCategory10}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen11}></img>
                          <h3>{BusinessCategory11}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen12}></img>
                          <h3>{BusinessCategory12}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen13}></img>
                          <h3>{BusinessCategory13}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen14}></img>
                          <h3>{BusinessCategory14}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen15}></img>
                          <h3>{BusinessCategory15}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen16}></img>
                          <h3>{BusinessCategory16}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen17}></img>
                          <h3>{BusinessCategory17}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen18}></img>
                          <h3>{BusinessCategory18}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen19}></img>
                          <h3>{BusinessCategory19}</h3>
                          </NavLink>
                        </div>

                        <div className="category-screen">
                        <NavLink to="../contactus">
                          <img alt="catImg" src={BusinessCategoryScreen20}></img>
                          <h3>{BusinessCategory20}</h3>
                          </NavLink>
                        </div>

                    </div>

                </div>

                <div className="ecom-category-section">

                  <h1>e-Commerce Web App</h1>

                    
                    <div className="ecom-category-screens">

                        <h2>{EcomCategory01}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen01a}></img>
                              <h3>{ecomCategory01a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen01b}></img>
                               <h3>{ecomCategory01b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen01c}></img>
                                <h3>{ecomCategory01c}</h3>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory02}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen02a}></img>
                              <h3>{ecomCategory02a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen02b}></img>
                               <h3>{ecomCategory02b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen02c}></img>
                                <h3>{ecomCategory02c}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen02d}></img>
                                <h3>{ecomCategory02d}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen02e}></img>
                                <h3>{ecomCategory02e}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen02f}></img>
                                <h3>{ecomCategory02f}</h3>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory03}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen03a}></img>
                              <h3>{ecomCategory03a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03b}></img>
                               <h3>{ecomCategory03b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03c}></img>
                                <h3>{ecomCategory03c}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03d}></img>
                                <h3>{ecomCategory03d}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03e}></img>
                                <h3>{ecomCategory03e}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03f}></img>
                                <h3>{ecomCategory03f}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen03g}></img>
                                <h3>{ecomCategory03g}</h3>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory04}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen04a}></img>
                              <h3>{ecomCategory04a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen04b}></img>
                               <h3>{ecomCategory04b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen04c}></img>
                                <h3>{ecomCategory04c}</h3>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory05}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen05a}></img>
                              <h3>{ecomCategory05a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen05b}></img>
                               <h3>{ecomCategory05b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen05c}></img>
                                <h3>{ecomCategory05c}</h3>
                                </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen05d}></img>
                                <h3>{ecomCategory05d}</h3>
                                </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory06}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen06a}></img>
                              <h3>{ecomCategory06a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen06b}></img>
                               <h3>{ecomCategory06b}</h3>
                               </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory07}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen07a}></img>
                              <h3>{ecomCategory07a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen07b}></img>
                               <h3>{ecomCategory07b}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen07c}></img>
                               <h3>{ecomCategory07c}</h3>
                               </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen07d}></img>
                               <h3>{ecomCategory07d}</h3>
                               </NavLink>
                            </div>

                        </div>

                    </div>

                    <div className="ecom-category-screens">

                        <h2>{EcomCategory08}</h2>

                        <div className="sub-cat-screens snaps-inline">

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                             <img alt="catImg" src={ecomCategoryScreen08a}></img>
                              <h3>{ecomCategory08a}</h3>
                              </NavLink>
                            </div>

                            <div className="ecom-category-screen">
                            <NavLink to="../contactus">
                               <img alt="catImg" src={ecomCategoryScreen08b}></img>
                               <h3>{ecomCategory08b}</h3>
                               </NavLink>
                            </div>

                            

                        </div>

                    </div>

                    
               </div>

            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`

.our-work {
    background-color: ${({ theme }) => theme.colors.darkBlack};
   ${'' /* display:flex;
   flex-direction:column;
   align-items:center; */}
   margin-top:-4rem;

    p {
            font-size: 2rem;
            font-weight: 550;
            margin-top:3rem;
            padding:3rem 1rem;
            text-align:center;
            
    }

    .category-section  {
        margin:1rem 5rem;
        padding:1rem 5rem;
        background-color:${({ theme }) => theme.colors.black};
        ${'' /* border: 1px solid ${({ theme }) => theme.colors.white}; */}
        border-radius: 5rem;
       h1 {
           font-size: 4rem;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 1rem;
            
       }

       .category-screens {
            display:grid;
            gap: 2rem;
            grid-auto-flow:column; 
            grid-auto-columns: 20%;
            overscroll-behavior-inline: contain;
            padding:1rem 5rem;
            overflow-x: auto;

           .category-screen {
            display:grid;
            gap:2rem;
             padding:3rem;
             ${'' /* background-color:${({ theme }) => theme.colors.bg}; */}
             border-radius: 2rem;

              img {
                    inline-size:100%;
                    aspect-ratio: 20 / 9;
                    object-fit:cover;
                  }

                  h3 {
                    text-align:center;
                  }  

                  &:hover {
                    background-color:${({ theme }) => theme.colors.darkBlack};
                  } 
       }

     
       }

       .snaps-inline {
         scroll-snap-type: inline mandatory;
        

        .snaps-inline > * {
            scroll-snap-align: start;
        }
    }
}

.ecom-category-section {

    display:flex;
    flex-direction: column;
    margin-top:4rem;
    padding:1rem 5rem;
    gap:2rem;
    h1 {
            font-size: 4rem;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            
       }

       .ecom-category-screens {
        display:flex;
        flex-direction: column;
        border: 1px solid ${({ theme }) => theme.colors.black};
        border-radius: 5rem;
        background-color:${({ theme }) => theme.colors.black};
        gap:1rem;

            h2 {
                font-size: 3rem;
                font-weight:500;
            text-align: center;
            margin-top: 3rem;
            margin-bottom: 3rem;
            }

            .sub-cat-screens {
                display:grid;
                gap: 2rem;
                grid-auto-flow:column; 
                grid-auto-columns: 20%;
                overscroll-behavior-inline: contain;
                margin:1rem 5rem;
                padding:1rem 5rem;
                overflow-x: auto;

            .ecom-category-screen {
                display:grid;
                gap:2rem;
                padding:3rem;
             ${'' /* background-color:${({ theme }) => theme.colors.bg}; */}
                border-radius: 2rem;

              img {
                    inline-size:100%;
                    aspect-ratio: 20 / 9;
                    object-fit:cover;
                  }

                  h3 {
                    text-align:center;
                  }   
            }

            .ecom-category-screen:hover {
                background-color:${({ theme }) => theme.colors.darkBlack};
            }
            }

            .snaps-inline {
         scroll-snap-type: inline mandatory;
        

        .snaps-inline > * {
            scroll-snap-align: start;
        }
       }
}
}
}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
  .our-work {
    margin-top:-5rem;

    .category-section {
      margin:1rem 2rem;

      h1 {
        font-size: 3.5rem;
      }

      .category-screens {
        gap: 1rem;
        grid-auto-columns: 25%;
        padding:1rem 1rem;

        .category-screen {
          padding:1rem;
        }
      }
    }

    .ecom-category-section {
      margin-top:2rem;
      padding:1rem 2rem;
      h1 {
        font-size: 3rem;
        margin-top: 1rem;
            margin-bottom: 1rem;
      }

      .ecom-category-screens{
        h2 {
          font-size: 2.5rem;
        }

        .sub-cat-screens {
          gap: 1rem;
          grid-auto-columns: 25%;
          margin:1rem 1rem;
          padding:1rem 1rem;
        }
      }
    }
  } 

}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {

    .our-work {
        
        margin-top:-16rem;

        .category-section {
            margin:1rem 1.5rem;
            padding:1rem 1rem;
            
            border-radius: 4rem;
            
            h1 {
                
                font-size: 2rem;
                margin-top: 1rem;
            ${'' /* margin-bottom: 1rem; */}
            }

            .category-screens  {
                gap: 1rem;
                grid-auto-columns: 33%;
                padding:1.5rem 0.5rem;
                
                .category-screen  {
                    padding:0.5rem 0.5rem;
                    

                    h3 {
                        font-size: 1.5rem;
                      
                    }
                }
            } 
        }

    .ecom-category-section  {
        margin-top:1rem;
        padding:1rem 1.5rem;
        gap:1rem;

        h1 {
            font-size: 2.5rem;
           
        }

        .ecom-category-screens {
            border-radius: 2rem;

            h2 {
                font-size: 2.5rem;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            .sub-cat-screens {
              gap: 1rem;
          grid-auto-columns: 33%;
                margin:1rem 1rem;
                padding:1rem 1rem;
            }

            .ecom-category-screen  {
                padding:1rem;
            }


        }


    }


    }

    
}


`;

export default OurWork;