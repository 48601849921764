import React, {useRef} from "react";
import { useGlobalContext } from "../context";
import styled from "styled-components";
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const {ContactFormHead, ContactFormIcon} = useGlobalContext();

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
        'service_kxk2ji7', 
        'template_qxj30jb', 
        form.current, 
        'z6T6q0C-_6_fTIHMf'
        )
      .then((result) => {
          console.log(result.text);
          console.log("Meassage Sent");
      }, (error) => {
          console.log(error.text);
      });
  };

    return (
        <Wrapper>
            <div className="contact-form">

              <div className="contact-form-top">
                <p>{ContactFormHead}</p>
              </div>

              <div className="contact-form-bottom">
  
                <div className="contact-icon">
                  <img alt="contactIcon" src={ContactFormIcon}></img>
                </div>

                <div className="contact-inputs">

                  <form ref={form} onSubmit={sendEmail} className="contact-input">

                   <label>Name</label>
                   <input type="text" name="user_name" />
                   <label>Company Name</label>
                   <input type="text" name="company_name" />
                   <label>Email</label>
                   <input type="email" name="user_email" />
                   <label>Mobile Number</label>
                   <input type="number" name="user_mobile" />
                   <label>How can we help you?</label>
                   <textarea name="message" />
                   <input type="submit" value="Send" className="contact-send" />

                  </form>

                </div>
             </div>
            </div>
        </Wrapper>
    )

};

const Wrapper = styled.section`

.contact-form {
  display:flex;
  flex-direction:column;
  align-items:center;
    background-color: ${({ theme }) => theme.colors.darkBlack};
    padding-top:5rem;
    margin-top:-2rem;

    .contact-form-top {
        p {
            font-size: 3rem;
            margin-bottom: 10rem;
            align-items: center;
            text-align:center;
            width: 70%;
            margin-left:20rem;
          }
    }

    .contact-form-bottom {
      display: flex;
      justify-content:space-around;
      align-items:center;
      ${'' /* border: 2px solid #fff; */}

      

      .contact-icon {
        
      }

      .contact-inputs {
        ${'' /* border: 2px solid #fff; */}
        border-radius:2rem;
        padding: 5rem 7rem;
        background-color: ${({ theme }) => theme.colors.black};

        .contact-input {
          display:flex;
          flex-direction:column;
          gap:0.5rem;
          ${'' /* border: 2px solid #fff; */}
          

          label {
            color: ${({ theme }) => theme.colors.white};
            font-size:1.5rem;
            margin-bottom:0.3rem;
            
          }

          input {
            color: ${({ theme }) => theme.colors.black};
            width:40rem;
            height:3rem;
            border-radius:1rem;
            font-size:1.5rem;
            
          }

          textarea {
            font-size:1.5rem;
          }

          .contact-send {
            background-color: ${({ theme }) => theme.colors.blue};
            width:10rem;
            margin-top:1rem;
            padding:0.5rem 0.5rem;
            font-size:1.5rem;
            border-radius:1rem;
            cursor:grab;
            color: ${({ theme }) => theme.colors.white};
            transition: all 0.3s ease 0s;

            -webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;

            &:hover {
              background-color: ${({ theme }) => theme.colors.green};
              
              


            }
          }


        }

      }
    }

}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {

  .contact-form {
    marggin-top:-10rem;
    padding-bottom:10rem;


    .contact-form-top {
      p {
        margin-left:10rem;
        width: 80%;
      }
    }

    .contact-form-bottom {
      flex-direction:column;
    }
  }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
  .contact-form {
    marggin-top:-10rem;
    padding-bottom:10rem;


    .contact-form-top {
      p {
        margin-left:7rem;
        width: 80%;
        font-size: 2.5rem;
      }
    }

    .contact-form-bottom {
      flex-direction:column;

      .contact-icon {
        img {
          width:40rem;
          height:auto;
        }
      }
    }
  }
}
`;

export default ContactForm;