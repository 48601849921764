import React from "react";
import styled from "styled-components";

const BlogPage = () => {

    return (
        <Wrapper>
        <div className="blog-page">
         <h2>Blogs coming soon</h2>

        </div>
        </Wrapper>
    )

}

const Wrapper = styled.section`
 .blog-page {
    background-color: ${({ theme }) => theme.colors.darkBlack};
    h2 {
        padding-top: 3rem;
        ${'' /* color: ${({ theme }) => theme.colors.black}; */}
    }
 }

 //998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .blog-page {
        margin-top:-2rem;

        h2 {
            padding-top: 3rem;
        }
    }
}

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {
    .blog-page {
        margin-top:-14rem;

        h2 {
            padding-top: 5rem;
           
        }
    }
}
`;



export default BlogPage;

