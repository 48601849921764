import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { MenuItems } from "./MenuItems";
import { Button } from "../Styles/Button";
import { useGlobalContext } from "../context";



class Navbar extends Component {

   

state = {clicked: false}; 

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  

render() {

   
    // const{LogoWcs} = useGlobalContext();
    
    return(
        <Wrapper>
            <div className="navbarItems" >

                <div className="logo-title">

                    <img src="./Images/LogoWcs.png"  className="logo-wcs"/>
                    <h2 className="companyName">WCS</h2>

                </div>

                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"} ></i>
                </div>

                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"   }>
                      {
                        MenuItems.map((item, index) => {
                       return(
                        <li key={index}>
                                <NavLink className={item.cName} to={item.url}>
                                    <i className={item.icon}></i>
                                    {item.title}
                                </NavLink>
                        </li>
                           )
                         })
                        }
                </ul>

            </div>
        </Wrapper>
    )
}

}



const Wrapper = styled.section`

.navbarItems {
    background-color: ${({ theme }) => theme.colors.bg};
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:0 3rem;
    box-shadow: 0 5px 15px 0 rgba(0,0,0, .25);
    width:95%;
    height:80px;
    border-radius: 13px;
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999;
    opacity:90%;

    .logo-title {
        display:flex;
        align-items:center;
        justify-content:space-between;
        gap: 1rem;
        margin-left: 2rem;

        .logo-wcs {
            height: 4.5rem;
            width: 4.5rem;
        }
        .companyName {
            font-weight:550;
            cursor: pointer;
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .menu-icons {
        display: none;
    }

    .nav-menu {
        display:flex;
        grid-template-columns: repeat(6, auto);
        gap: 2.5rem;
        align-items: center;

        .nav-links {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.black};
            padding: 0.5rem 1rem;
            white-space: nowrap;
            font-weight:520;

            .nav-links i {
              padding: 1rem 1rem;
              margin-left: -1rem;
            }
        }

        .nav-links:hover {
            background-color: ${({ theme }) => theme.colors.blue};
            color: ${({ theme }) => theme.colors.white};
            border-radius: 10px;
            transition: all 0.1s ease-in-out;
        }
    }
}

//998px
@media (max-width:${({ theme }) => theme.media.tab}) {
    .navbarItems {
        padding:0 2rem;

        .logo-title  {

            .companyName {
                ${'' /* font-size: 3.5rem; */}
            }
        }

        .nav-menu {
            gap: 2rem;
            font-size: 1.8rem;

            .nav-links {
                padding: 0.5rem 0.5rem;
            }
        }


    }
} 

//768px
@media (max-width:${({ theme }) => theme.media.mobile}) {

.navbarItems {
    z-index:99;

    .logo-title {
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap: 1rem;
    margin-left: -1rem;
   }

   .menu-icons {
    display:block;
    cursor:pointer;
    margin-left:15rem;
    
    i {
        font-size: 4rem;
    }
   }

   .nav-menu {
    display:flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.bg};
    width:100%;
    height:auto;
    position: absolute;
    top:0;
    left:-100%;
    opacity:0;
    align-items:stretch;
    padding: 9rem 0 3rem 0;
    margin:0;
    transition: 0.4s ease-in-out;
    border-radius: 1.3rem;
   }

   .nav-menu.active {
    left:0;
    opacity:1;
    z-index:-1;
    transition: 0.3s ease-in-out;
   }

   .nav-links {
    display:block;
    width:100%;
    font-size:10rem;
    padding: 2rem 0;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.white};
   }
}
}
`;

export default Navbar;